const SimpleSQLFormatter = (query: string) => {
    const formatSQL = (sql: string) => {
        const keywords = ["SELECT", "FROM", "WHERE", "JOIN", "LEFT JOIN", "RIGHT JOIN", "INNER JOIN", "GROUP BY", "HAVING", "ORDER BY"];
        let formattedQuery = sql.trim();

        keywords.forEach(keyword => {
            formattedQuery = formattedQuery.replace(new RegExp(keyword, "gi"), "\n" + keyword);
        });

        return formattedQuery.split("\n").map((line, index) => <div key={index}>{line}</div>);
    };

    return (
        <pre
            style={{
                fontFamily: "monospace",
                fontSize: "0.8em",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word"
            }}
        >
            {formatSQL(query)}
        </pre>
    );
};

export default SimpleSQLFormatter;
