import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { PublicClientApplication, AccountInfo, InteractionRequiredAuthError, AuthenticationResult, EventType } from "@azure/msal-browser";
import { msalConfig, authMS, authLOCAL, authRESET, getRedirectUri, loginRequest } from "./authConfig";
import { Navigate } from "react-router-dom";

interface MsalContextType {
    msalInstance: PublicClientApplication | null;
    updateAuthority: (newAuthority: string) => Promise<void>;
    getToken: (scopes: string[]) => Promise<AuthenticationResult | null>;
}

const MsalContext = createContext<MsalContextType | undefined>(undefined);

interface MsalProviderProps {
    children: ReactNode;
}

export const MsalProvider = ({ children }: MsalProviderProps) => {
    const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null);
    const setLocalUserFlowCache = (eventAuthority: string) => {
        if (eventAuthority) {
            import.meta.env.DEV === true && console.log("MSAL¦SET_LOCALSTORE¦msalAuth4 ⇒", msalConfig);
            localStorage.setItem("msalAuth4", eventAuthority);
        }
    };

    useEffect(() => {
        const initializeMsal = async () => {
            const instance = new PublicClientApplication(msalConfig);
            await instance.initialize();

            instance?.addEventCallback(event => {
                if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
                    import.meta.env.DEV === true && console.log("MSAL¦Callback⇒ACQUIRE_TOKEN_SUCCESS", event.payload);

                    setLocalUserFlowCache((event.payload as any).authority);

                    const account = event.payload as AccountInfo;
                    instance.setActiveAccount(account);
                } else if (event.eventType === EventType.LOGIN_SUCCESS) {
                    import.meta.env.DEV === true && console.log("MSAL¦Callback⇒LOGIN_SUCCESS", event.payload);

                    setLocalUserFlowCache((event.payload as any).authority);

                    const account = event.payload as AccountInfo;
                    instance.setActiveAccount(account);
                } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                    //import.meta.env.DEV === true && console.log("MSAL¦Callback⇒ACQUIRE_TOKEN_FAILURE", event.payload);
                } else if (event.eventType === EventType.LOGOUT_END) {
                    import.meta.env.DEV === true && console.log("MSAL¦Callback⇒LOGOUT_END", event.payload);

                    localStorage.removeItem("msalAuth4");
                    window.location.href = "/";
                } else if (event.eventType === EventType.LOGIN_FAILURE && event.error && (event.error as any).errorMessage) {
                    import.meta.env.DEV === true && console.log("MSALCNFG¦Callback⇒LOGIN_FAILURE", event.payload);

                    if ((event.error as any).errorMessage.includes("AADB2C90118")) {
                        if (localStorage.getItem("msalAuth4") != null) {
                            //import.meta.env.DEV === true && console.log("MSAL¦Callback⇒LOGIN_FAILURE¦PASS_RESET⇒AADB2C90118", event);
                        } else {
                            import.meta.env.DEV === true && console.log("MSAL¦Callback⇒LOGIN_FAILURE¦OTHER_ERR", event);
                        }
                    }
                } else if (event.eventType === EventType.ACQUIRE_TOKEN_NETWORK_START) {
                    import.meta.env.DEV === true && console.log("MSALCNFG¦Callback⇒ACQUIRE_TOKEN_NETWORK_START", event.payload);
                    setLocalUserFlowCache((event.payload as any).authority);

                    const account = event.payload as AccountInfo;
                    instance.setActiveAccount(account);
                } else {
                    import.meta.env.DEV === true && console.log("MSAL¦Callback⇒OTHER_RESPONSE", event);
                }
            });
            if (localStorage.getItem("isFrmI") !== null) {
                import.meta.env.DEV === true && console.log("IFRM¦handleRedirectPromise");

                const redirectResponse = await instance.handleRedirectPromise();
                let accessToken = redirectResponse?.accessToken;
            }
            import.meta.env.DEV === true && console.log("MSAL¦UI¦setMsalInstance", instance);
            setMsalInstance(instance);
        };
        import.meta.env.DEV === true && console.log("MSAL¦UI¦initializeMsal");

        if (!localStorage.getItem("msalAuth4") || localStorage.getItem("msalAuth4") == null) {
            import.meta.env.DEV === true && console.log("MSAL¦UI⇒NO USER FLOW CACHE");
        } else {
            import.meta.env.DEV === true && console.log("MSAL¦UI⇒YES USER_FLOW CACHE", localStorage.getItem("msalAuth4"));
            //msalConfig.auth.authority = localStorage.getItem("msalAuth4") || authLOCAL.authority;
        }

        initializeMsal();
    }, []);

    const updateAuthority = async (newAuthority: string) => {
        import.meta.env.DEV === true && console.log("MSAL¦UPDATE_AUTH¦newAuthority", newAuthority);
        if (msalInstance) {
            const newConfig = {
                ...msalConfig,
                auth: {
                    ...msalConfig.auth,
                    authority: newAuthority
                }
            };
            import.meta.env.DEV === true && console.log("MSAL¦UPDATE_AUTH¦NEW_INSTANCE¦newConfig", newConfig);
            const newInstance = new PublicClientApplication(newConfig);
            await newInstance.initialize();
            setMsalInstance(newInstance);
        }
    };

    const getToken = async (scopes: string[]): Promise<AuthenticationResult | null> => {
        if (!msalInstance) {
            import.meta.env.DEV === true && console.log("MSAL¦getToken⇒NO_MSAL_INSTANCE");
            return null;
        }
        const account = msalInstance.getActiveAccount();

        if (!account) {
            import.meta.env.DEV === true && console.log("MSAL¦getToken¦NO_ACCOUNT", msalInstance, account);
            return null;
        } else {
            import.meta.env.DEV === true && console.log("MSAL¦getToken¦YES_ACCOUNT YES MSAL_INSTANCE", msalInstance, account);
        }
        const request = {
            account: account,
            scopes: scopes
        };
        try {
            import.meta.env.DEV === true && console.log("MSAL¦getToken¦acquireTokenSilent⇒Start", msalInstance, account);
            const response = await msalInstance.acquireTokenSilent(request);
            import.meta.env.DEV === true && console.log("MSAL¦getToken¦acquireTokenSilent⇒End", response);
            return response;
        } catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
                import.meta.env.DEV === true && console.log("MSAL¦getToken¦ERR⇒InteractionRequiredAuthError", e);
                return null;
            } else {
                //import.meta.env.DEV === true && console.log("MSAL¦getToken¦ERR⇒OTHER", e);
                console.error(e);
                return null;
            }
        }
    };
    return <MsalContext.Provider value={{ msalInstance, updateAuthority, getToken }}>{children}</MsalContext.Provider>;
};

export const useMsal = (): MsalContextType => {
    const context = useContext(MsalContext);
    if (!context) {
        throw new Error("useMsal must be used within a MsalProvider");
    }
    return context;
};
