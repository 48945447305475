import React, { useState, useEffect } from "react";

import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";
import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";

import {
    DocumentCard,
    DocumentCardTitle,
    IDocumentCardPreviewImage,
    DocumentCardPreview,
    DocumentCardType,
    IDocumentCardPreviewProps
} from "@fluentui/react/lib/DocumentCard";
import MarkdownIt from "markdown-it";
import { ImgMapKeys, imgMap } from "../../lsts";
import { gselectedgroup, gfile, gloggedinuser } from "../../interfaces";
import { removeExtension, fetchThumbnail } from "../../util_glob";
import lines_placeholder from "../../assets/thumb_placeholder.png";
const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    breaks: true
})
    .enable("table")
    .enable("strikethrough")
    .disable("code");

interface docMess {
    selectedGroup: gselectedgroup;
    fileList: gfile[];
    loggedInUser: gloggedinuser;
    onCitationClicked: (filePath: string, integratedCitation: string | null) => void;
}

export const AnswerDocs = ({ selectedGroup, fileList, loggedInUser, onCitationClicked }: docMess) => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });
    const previewProps: IDocumentCardPreviewProps = {
        previewImages: [
            {
                name: "Assistant",
                previewImageSrc: selectedGroup?.headerimageurl || imgMap[(selectedGroup?.assistanttype ?? "anya") as ImgMapKeys],
                imageFit: 2,
                height: 120
            } as IDocumentCardPreviewImage
        ]
    };

    const [thumbnailUrls, setThumbnailUrls] = useState<string[]>(fileList.map(() => lines_placeholder));

    useEffect(() => {
        const fetchThumbnails = async () => {
            for (let i = 0; i < fileList.length; i++) {
                const url = await fetchThumbnail(fileList[i], selectedGroup);
                setThumbnailUrls(prevUrls => {
                    const updatedUrls = [...prevUrls];
                    updatedUrls[i] = url;
                    return updatedUrls;
                });
            }
        };
        fetchThumbnails();
    }, [fileList, selectedGroup]);
    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.answerContainerHI} verticalAlign="space-between" tokens={{ childrenGap: 10 }} styles={{ root: { padding: "10px" } }}>
                <AnswerIcon />
                <DocumentCardPreview {...previewProps} className={styles.assistantHiMessageImage} />
            </Stack>
            <Stack
                horizontal
                tokens={{ childrenGap: 20 }}
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                    gap: "10px",
                    margin: "15px 0",
                    alignContent: "center"
                }}
            >
                {fileList.slice(0, 20).map((file, index) => (
                    <Stack.Item key={index}>
                        <DocumentCard
                            key={index}
                            type={DocumentCardType.normal}
                            className={styles.hiDocCard}
                            onClick={() => {
                                onCitationClicked(`${file.name}#page=1_fid=${file.fileid}`, null);
                            }}
                        >
                            <DocumentCardTitle title={removeExtension(file.name)} shouldTruncate={true} className={styles.docCardTitle} />
                            <DocumentCardPreview
                                key={index}
                                previewImages={[
                                    {
                                        previewImageSrc: thumbnailUrls[index],
                                        imageFit: 2,
                                        name: file.name
                                    }
                                ]}
                                styles={{
                                    root: { margin: "0px", overflow: "hidden", borderRadius: "5px", padding: "0px", overflowY: "hidden" }
                                }}
                            />
                        </DocumentCard>
                    </Stack.Item>
                ))}
            </Stack>
        </animated.div>
    );
};
