import React from "react";
import styles from "./AssistantTypeCards.module.css";
import { DocumentCard, DocumentCardTitle, DocumentCardDetails, DocumentCardImage } from "@fluentui/react/lib/DocumentCard";
import { ThemeProvider, TooltipHost, DirectionalHint } from "@fluentui/react";
import { ImageFit } from "@fluentui/react/lib/Image";
import { assistantCardItems } from "../../lsts";
//import customTheme from "./../../theme/customTheme";
import { assistantTypeDocCardStyles, tooltipNoBeakStyles } from "../../styles_glob";

interface assistantTypeProps {
    addSpace: (specializedSettings: any) => void;
}
const tooltipWithHTML = ({ content }: { content: string }) => {
    const htmlContent = { __html: content };
    return (
        <TooltipHost content={<div dangerouslySetInnerHTML={htmlContent} />}>
            <span>Hover over me!</span>
        </TooltipHost>
    );
};

const AssistantTypeCards: React.FC<assistantTypeProps> = ({ addSpace }) => {
    return (
        <div className={styles.cardContainer}>
            {assistantCardItems
                .filter(item => item.disabled != true)
                .map((item, index) =>
                    item.commingSoon != true ? (
                        <DocumentCard
                            key={index}
                            className={styles.card}
                            onClick={() => {
                                addSpace(item.creationData);
                            }}
                            styles={assistantTypeDocCardStyles}
                        >
                            <TooltipHost
                                content={<div dangerouslySetInnerHTML={{ __html: item.tooltip }} />}
                                directionalHint={DirectionalHint.topRightEdge}
                                calloutProps={tooltipNoBeakStyles}
                            >
                                <div className={styles.overlay}>
                                    <span className={styles.overlayText}>Create</span>
                                </div>
                                <DocumentCardImage height={150} imageFit={ImageFit.centerCover} imageSrc={item.imageSrcSmall} />
                                <DocumentCardDetails>
                                    <DocumentCardTitle title={item.title} className={styles.cardTitle} />
                                    <DocumentCardTitle title={item.description} shouldTruncate showAsSecondaryTitle className={styles.cardDesc} />
                                </DocumentCardDetails>
                            </TooltipHost>
                        </DocumentCard>
                    ) : (
                        <DocumentCard key={index} className={styles.cardCommingSoon} styles={assistantTypeDocCardStyles}>
                            <TooltipHost
                                content={<div dangerouslySetInnerHTML={{ __html: item.tooltip }} />}
                                directionalHint={DirectionalHint.topRightEdge}
                                calloutProps={tooltipNoBeakStyles}
                            >
                                <div className={styles.overlayCommingSoon}>
                                    <span className={styles.overlayText}>Coming Soon</span>
                                </div>
                                <DocumentCardImage height={150} imageFit={ImageFit.centerCover} imageSrc={item.imageSrcSmall} />
                                <DocumentCardDetails>
                                    <DocumentCardTitle title={item.title} className={styles.cardTitle} />
                                    <DocumentCardTitle title={item.description} shouldTruncate showAsSecondaryTitle className={styles.cardDesc} />
                                </DocumentCardDetails>
                            </TooltipHost>
                        </DocumentCard>
                    )
                )}
        </div>
    );
};

export default AssistantTypeCards;
