import { useState, useEffect } from "react";
import { pdfjs } from "react-pdf";

import styles from "./XplrrPDF.module.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import stringSimilarity from "string-similarity";

import { ThemeProvider } from "@fluentui/react";
import customTheme from "./../../theme/customTheme";

import { gdiff, gfile, gselectedgroup, gdiffresponse, gpagedimensions, gdiffparagraphresult } from "../../interfaces";
import { getDesiredWidthDiff, getDesiredHeightDiff } from "../../util_glob";

import PageSelector from "../PageSelector/PageSelector";
import XplrrBOXDIFFPDF from "../XplrrBOXPDF/XplrrBOXDIFFPDF";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface ViewerPDFProps {
    fileItem1: gfile | undefined;
    fileItem2: gfile | undefined;
    selectedGroup: gselectedgroup;
    rndo: string;
    diffRes?: gdiffresponse[];
}

const XplrrPDFDiff = ({ fileItem1, fileItem2, selectedGroup, rndo, diffRes }: ViewerPDFProps) => {
    const [file1, setFile1] = useState<gfile>();
    const [file2, setFile2] = useState<gfile>();

    const [diffRes1, setDiffRes1] = useState<gdiffparagraphresult | undefined>(
        diffRes ? diffRes[0].paragraphs.find(item => `${item?.fid}` == fileItem1?.fileid) : undefined
    );
    const [diffRes2, setDiffRes2] = useState<gdiffparagraphresult | undefined>(
        diffRes ? diffRes[0].paragraphs.find(item => `${item?.fid}` == fileItem2?.fileid) : undefined
    );

    const [currentPage1, setCurrentPage1] = useState(0);
    const [currentPage2, setCurrentPage2] = useState(0);
    const [numPages1, setNumPages1] = useState<number>();
    const [numPages2, setNumPages2] = useState<number>();
    const [pageLoaded1, setPageLoaded1] = useState(false);
    const [pageLoaded2, setPageLoaded2] = useState(false);
    const [manualSection, setManualSecton] = useState<number>();
    useEffect(() => {
        setFile1(fileItem1);
        setFile2(fileItem2);
        setDiffRes1(diffRes ? diffRes[0].paragraphs.find(item => `${item?.fid}` == fileItem1?.fileid) : undefined);
        setDiffRes2(diffRes ? diffRes[0].paragraphs.find(item => `${item?.fid}` == fileItem2?.fileid) : undefined);
    }, [fileItem1, fileItem2]);

    const INCHES_TO_POINTS = 72;
    const diffHandler = (diffRes: gdiffparagraphresult, fileid: string, order: number, dims: gpagedimensions[]) => {
        setTimeout(() => {
            diffRes.paragraphs.forEach((paragraph, paraIndex) => {
                let para_page_num = paragraph.bounding_regions[0].page_number;
                let jspositions = getTextSpanPositions(fileid, para_page_num - 1);

                paragraph.kgnf_lines.forEach((line, lineIndex) => {
                    let line_page_num = line.page_num ?? para_page_num;

                    let page_width = getDesiredWidthDiff("inv");
                    let page_height = getDesiredHeightDiff(dims[line_page_num - 1], "inv");

                    const x_scale = page_width / dims[line_page_num - 1].width;
                    const y_scale = page_height / dims[line_page_num - 1].height;

                    let poly = line.polygon || [];
                    const pixelCoordinates = poly.map(point => ({
                        x: point.x * INCHES_TO_POINTS * x_scale,
                        y: point.y * INCHES_TO_POINTS * y_scale
                    }));

                    const xValues = pixelCoordinates.map(point => (point.x / page_width) * 100);
                    const yValues = pixelCoordinates.map(point => (point.y / page_height) * 100);

                    const left = Math.min(...xValues);
                    const right = Math.max(...xValues);
                    const top = Math.min(...yValues);
                    const bottom = Math.max(...yValues);

                    //console.log("page_height", line.content, line);
                    let jsmatch = findClosestMatchesPack(left, top, line.content, jspositions);
                    //console.log("jsmatch", jsmatch.left, left, jsmatch.top, top, jsmatch.similarity, jsmatch.text, line.content);
                });
            });
        }, 600);
    };
    function findClosestMatchesPack(targetLeft: number, targetTop: number, targetText: string, array: any[], numMatches = 5) {
        const scored = array.map(item => ({
            ...item,
            distance: Math.sqrt(Math.pow(item.left - targetLeft, 2) + Math.pow(item.top - targetTop, 2)),
            similarity: stringSimilarity.compareTwoStrings(targetText, item.text)
        }));
        return scored
            .sort((a, b) => a.distance - b.distance)
            .slice(0, numMatches)
            .sort((a, b) => b.similarity - a.similarity)[0];
    }
    function calculateTextSimilarity(str1: string, str2: string) {
        const s1 = str1.toLowerCase();
        const s2 = str2.toLowerCase();
        const pairs1 = getPairs(s1);
        const pairs2 = getPairs(s2);
        const union = new Set([...pairs1, ...pairs2]);
        const intersection = new Set([...pairs1].filter(x => pairs2.has(x)));
        return (2.0 * intersection.size) / union.size;
    }
    function getPairs(str: string) {
        const pairs = new Set();
        for (let i = 0; i < str.length - 1; i++) {
            pairs.add(str.slice(i, i + 2));
        }
        return pairs;
    }
    function getTextSpanPositions(itemid: string, pageidx: number) {
        const container = document.querySelector(`[data-diff-page="DDP_${itemid}_${pageidx}"] .react-pdf__Page__canvas`);
        if (!container) {
            console.error("container‾not‾found");
            return [];
        }
        // Get container dimensions for normalization
        const containerRect = container.getBoundingClientRect();
        const containerWidth = containerRect.width;
        const containerHeight = containerRect.height;

        // Select all the text spans within the PDF text layer
        const pageitemscont = document.querySelector(`[data-diff-page="DDP_${itemid}_${pageidx}"] .react-pdf__Page__textContent.textLayer`);
        const textspans = pageitemscont?.querySelectorAll(".react-pdf__Page__textContent span");
        if (!textspans) {
            console.error("no‾spans");
            return [];
        }

        // Map through each span and calculate normalized positions
        const positions = Array.from(textspans)
            .map(span => {
                const spanRect = span.getBoundingClientRect();
                const normalizedLeft = ((spanRect.left - containerRect.left) / containerWidth) * 100;
                const normalizedTop = ((spanRect.top - containerRect.top) / containerHeight) * 100;
                const normalizedBottom = ((spanRect.bottom - containerRect.top) / containerHeight) * 100;

                return {
                    text: span.textContent,
                    left: parseFloat(normalizedLeft.toFixed(4) || "0"),
                    top: parseFloat(normalizedTop.toFixed(4) || "0"),
                    bottom: parseFloat(normalizedBottom.toFixed(4) || "0"),
                    span: span,
                    spanid: span.querySelector(":first-child")?.id,
                    pageidx: pageidx
                };
            })
            .filter(span => span.text != "" && span.left > 0 && span.top > 0);

        return positions;
    }
    function findClosestMatch(targetLeft: number, targetTop: number, array: any[]) {
        return array.reduce((closest, current) => {
            const currentDistance = Math.sqrt(Math.pow(current.left - targetLeft, 2) + Math.pow(current.top - targetTop, 2));
            const closestDistance = Math.sqrt(Math.pow(closest.left - targetLeft, 2) + Math.pow(closest.top - targetTop, 2));
            return currentDistance < closestDistance ? current : closest;
        });
    }
    const checkEvenOdd = (number: number) => {
        return number % 2 === 0 ? 2 : 3;
    };
    function shadeSection(topPercentage: number, bottomPercentage: number, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, chunkEven: number) {
        // Calculate actual pixel positions
        const startY = (topPercentage / 100) * canvas.height;
        const endY = (bottomPercentage / 100) * canvas.height;

        // Set the shading style (e.g., semi-transparent gray)
        if (chunkEven == 2) {
            ctx.filter = "blur(10px)";
            ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
            // Draw the rectangle from left edge to right edge
            ctx.fillRect(
                0, // x: start from left edge
                startY, // y: start from calculated top position
                canvas.width, // width: full width of canvas
                endY - startY // height: difference between end and start positions
            );
        }
        //if (chunkEven == 3) ctx.fillStyle = "rgba(0, 0, 0, 0.8)";
    }
    const handleTimeout = (fileid: string) => {
        console.log("handleTimeout", fileid);
        // window.globalDiff = diff || [];
        if (diffRes == undefined) return;

        console.log("diff", diffRes);
        if (fileid == `${file1?.fileid}`) {
            //const dims = file1?.pagedimensions ?? [];

            //const file1diff = diffRes[0].paragraphs.find(d => `${d.fid}` == file1?.fileid);
            //if (!file1diff) return;

            //diffHandler(file1diff, fileid, 0, dims);
            setPageLoaded1(true);
        } else if (fileid == `${file2?.fileid}`) {
            //const dims = file2?.pagedimensions ?? [];

            //const file2diff = diffRes[0].paragraphs.find(d => `${d.fid}` == file2?.fileid);
            //if (!file2diff) return;

            //diffHandler(file2diff, fileid, 0, dims);
            setPageLoaded2(true);
        }
    };
    const handlePageChangeFromPageSelector1 = (event: React.ChangeEvent<HTMLSelectElement>) => {
        window.globalUserScrollLazyLoadEnabled == true;
        setManualSecton(Number(event.target.value));
        setCurrentPage1(Number(event.target.value));
        scrollToPageNoSmooth(Number(event.target.value), file1?.fileid || "0");
        reendableUserScrollHandling();
    };
    const handlePageChangeFromPageSelector2 = (event: React.ChangeEvent<HTMLSelectElement>) => {
        window.globalUserScrollLazyLoadEnabled == true;
        setManualSecton(Number(event.target.value));
        setCurrentPage2(Number(event.target.value));
        scrollToPageNoSmooth(Number(event.target.value), file2?.fileid || "0");
        reendableUserScrollHandling();
    };
    const reendableUserScrollHandling = (tm: number = 500) => {
        setTimeout(() => (window.globalUserScrollLazyLoadEnabled = true), tm);
    };
    const scrollToPageNoSmooth = (pN: number, targetFile: string) => {
        if (targetFile == "0") return;
        var node = document.querySelector(`[data-diff-page="DDP_${targetFile}_${pN - 1}"`);
        if (node) node.scrollIntoView({ behavior: "auto", block: "center" });
    };
    const handleScroll1 = (event: any, pageNumCur: number) => {
        let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        setCurrentPage1(pageNumCur);
        const element = document.getElementById(`pdfmainid9${fileItem2?.fileid}`);
        if (element) {
            const scrollRatio = scrollTop / (scrollHeight - clientHeight);
            const targetScrollTop = Math.round(scrollRatio * (element.scrollHeight - element.clientHeight));
            element.scrollTop = targetScrollTop;
        }
    };
    const handleScroll2 = (event: any, pageNumCur: number) => {
        let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        setCurrentPage2(pageNumCur);
        const element = document.getElementById(`pdfmainid9${fileItem1?.fileid}`);
        if (element) {
            const scrollRatio = scrollTop / (scrollHeight - clientHeight);
            const targetScrollTop = Math.round(scrollRatio * (element.scrollHeight - element.clientHeight));
            element.scrollTop = targetScrollTop;
        }
    };
    const callBackVisibleEntries = (visibleEntries: number[]) => {};
    const setContentList = (contentList: { title: string; dest: number }[]) => {};
    const callBackIsLoading = (isLoading: boolean) => {};

    return (
        <div className={styles.xplrrPDFMain}>
            <div className={styles.xplrrPDFMainInternalWrap}>
                <ThemeProvider theme={customTheme}>
                    <div className={styles.pdfWrapXplrr} id="pdfWrapXplrrIDENT">
                        <div className={styles.pageSelectorXplrr}>
                            <div className={styles.pageSelectorOnly}>
                                <PageSelector
                                    currentPage={currentPage1}
                                    numPages={numPages1 ? numPages1 : 0}
                                    handlePageChange={handlePageChangeFromPageSelector1}
                                ></PageSelector>
                            </div>
                            <div className={styles.totPagesDisplay}>/ {numPages1}</div>
                        </div>
                        <div className={`${styles.pdfViewInv}`}>
                            <XplrrBOXDIFFPDF
                                key={`rndo_${rndo}_${file1?.fileid}`}
                                file={file1}
                                handleTimeout={handleTimeout}
                                handleScroll={handleScroll1}
                                setPages={(numPages: number) => {
                                    setNumPages1(numPages);
                                }}
                                startPage={1}
                                callBackVisibleEntries={callBackVisibleEntries}
                                setContentList={setContentList}
                                callBackIsLoading={callBackIsLoading}
                                companyId={selectedGroup.companyid}
                                srcLoc={"inv"}
                                diffPar={diffRes1}
                            />
                        </div>

                        <div className={`${styles.pdfViewInv}`}>
                            <XplrrBOXDIFFPDF
                                key={`rndo_${rndo}_${file2?.fileid}`}
                                file={file2}
                                handleTimeout={handleTimeout}
                                handleScroll={handleScroll2}
                                setPages={(numPages: number) => {
                                    setNumPages2(numPages);
                                }}
                                startPage={1}
                                callBackVisibleEntries={callBackVisibleEntries}
                                setContentList={setContentList}
                                callBackIsLoading={callBackIsLoading}
                                companyId={selectedGroup.companyid}
                                srcLoc={"inv"}
                                diffPar={diffRes2}
                            />
                            <div className={styles.pageSelectorXplrr}>
                                <div className={styles.pageSelectorOnly}>
                                    <PageSelector
                                        currentPage={currentPage2}
                                        numPages={numPages2 ? numPages2 : 0}
                                        handlePageChange={handlePageChangeFromPageSelector2}
                                    ></PageSelector>
                                </div>
                                <div className={styles.totPagesDisplay}>/ {numPages2}</div>
                            </div>
                        </div>
                    </div>
                </ThemeProvider>
            </div>
        </div>
    );
};

export default XplrrPDFDiff;
