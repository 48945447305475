import { gcaption, gpagedimensions, gfile, gselectedgroup } from "./interfaces";
import lines_placeholder from "./assets/thumb_placeholder.png";

export const getTranscriptFormattedDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day} ${month} ${hours}h${minutes}m`;
};
export const getNewTranscriptItemKey = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
};
export const formatDateConvoList = (date: string) => {
    const d = new Date(date);
    d.setDate(d.getDate() + 1); // Add 30 days
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2); // Pad month with zero
    const day = `0${d.getDate()}`.slice(-2); // Pad day with zero
    return `${year}-${month}-${day}`;
};
export const formatDate30DaysConvoList = (date: string) => {
    const d = new Date(date);
    d.setDate(d.getDate() - 30); // Add 30 days
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2); // Pad month with zero
    const day = `0${d.getDate()}`.slice(-2); // Pad day with zero
    return `${year}-${month}-${day}`;
};
export const onSTT = (text: string, language: string): void => {
    import.meta.env.DEV === true && console.log("¦whspr¦", text, language);
};
export const createWavBuffer = (samples: Int16Array, sampleRate: number): ArrayBuffer => {
    const buffer = new ArrayBuffer(44 + samples.length * 2);
    const view = new DataView(buffer);

    const bytesPerSample = 2; // 16-bit audio
    const numChannels = 1; // Mono
    const byteRate = sampleRate * numChannels * bytesPerSample;
    const blockAlign = numChannels * bytesPerSample;
    const bitsPerSample = 16;

    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + samples.length * bytesPerSample, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, byteRate, true);
    view.setUint16(32, blockAlign, true);
    view.setUint16(34, bitsPerSample, true);
    writeString(view, 36, "data");
    view.setUint32(40, samples.length * bytesPerSample, true);
    for (let i = 0; i < samples.length; i++) {
        view.setInt16(44 + i * 2, samples[i], true);
    }
    return buffer;
};
export const prepWavBuffer = (audioChunks: Float32Array[], recognitionResultOffset: number) => {
    const audioCtx = new AudioContext();
    const sampleRate = audioCtx.sampleRate;
    const startSample = Math.floor((recognitionResultOffset / 10000000) * sampleRate);
    const comboAudio = new Float32Array(audioChunks.reduce((acc, chunk) => acc + chunk.length, 0));
    let offset = 0;
    for (const chunk of audioChunks) {
        comboAudio.set(chunk, offset);
        offset += chunk.length;
    }
    let relevantAudio = comboAudio.slice(startSample);
    const int16Array = new Int16Array(relevantAudio.length);
    for (let i = 0; i < relevantAudio.length; i++) {
        int16Array[i] = Math.max(-32768, Math.min(32767, Math.floor(relevantAudio[i] * 32768)));
    }
    let wavBuffer = createWavBuffer(int16Array, sampleRate);
    return wavBuffer;
};
export const playWavBuffer = (buffer: ArrayBuffer) => {
    const audioContext = new AudioContext();
    audioContext.decodeAudioData(
        buffer,
        decodedData => {
            const source = audioContext.createBufferSource();
            source.buffer = decodedData;
            source.connect(audioContext.destination);
            source.start(0);
        },
        error => {
            console.error("Error decoding audio data", error);
        }
    );
};
export const handleFocus = (event: any) => {
    event.target.setSelectionRange(event.target.value.length, event.target.value.length);
};
export const encodeHTML = (str: string): string => {
    return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, " ");
};
export const makeValidHtmlId = (str: string) => {
    // Remove any character that isn't a letter, number, hyphen, underscore, colon, or period
    let validStr = str.replace(/[^a-zA-Z0-9-_:.]/g, "");
    // Ensure starts with letter
    if (!/^[a-zA-Z]/.test(validStr)) validStr = "id-" + validStr;

    // Ensure it's not empty
    if (validStr.length === 0) validStr = "id";

    return validStr;
};
export const shuffleArray = (array: string[]) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
};
export const getLoc = () => {
    return window.location.hash.replace("#", "");
};
export const genNewConvoKey = () => {
    return new Date() // Convo Key (Datetime String)
        .toISOString()
        .replace(/[-:.TZ]/g, "")
        .slice(0, 14);
};
export const isElementVisible = (eid: string) => {
    const element = document.getElementById(eid);
    if (!element) return false;
    return true;
};
export const convertMarkdownHeadersToHTML = (markdownText: string) => {
    return markdownText
        .replace(/^####\s+(.*)$/gm, "<h4>$1</h4>")
        .replace(/^###\s+(.*)$/gm, "<h3>$1</h3>")
        .replace(/^##\s+(.*)$/gm, "<h2>$1</h2>")
        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
};
export const copyToClipboard = (text: string) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {})
        .catch(err => {});
};
export const extractTextCptns = (content: string): gcaption | null => {
    content = content.toString();
    const colonIndex = content.indexOf(":");
    let contentText = "";
    if (colonIndex !== -1) {
        contentText = content.substring(colonIndex, content.length);
        content = content.substring(0, colonIndex);
    }
    // const regex = /^(.*?)#page=(\d+)_fid=(\d+)$/;

    const regex = /^(.*?)#page=([^_]+)_fid=(\d+)$/;
    const match = content.match(regex);
    if (match) {
        const [, filename, page, fileid] = match;
        return { content: contentText, filename, page, fileid };
    }
    return null;
};
export const addDotIfNeeded = (str: string) => {
    return str.endsWith(".") ? str : str + ".";
};
export const adjustHeight = (questionInputContainer: any) => {
    const inputContainers = document.getElementsByClassName(questionInputContainer);
    for (let i = 0; i < inputContainers.length; i++) {
        const inputContainer = inputContainers[i] as HTMLElement;
        let textArr = inputContainers[i].querySelectorAll("textarea");
        for (let j = 0; j < textArr.length; j++) {
            const textElement = textArr[j] as HTMLTextAreaElement;
            if (parseInt(inputContainer.style.height) < inputContainer.scrollHeight) {
                let height = textElement.scrollHeight + 15;
                inputContainer.style.height = height + "px";
            } else {
                let height = textElement.scrollHeight + 15;
                inputContainer.style.height = "auto";
                inputContainer.style.height = height + "px";
            }
        }
    }
};
export const isTouchDevice = () => {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};
const writeString = (view: DataView, offset: number, string: string) => {
    for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
    }
};
export const contentPath = (filename: string, fileid: string) => {
    return `${filename}_fid=${fileid}`;
};
export const pdfCacheKey = (fId: string, modifiedDateString: string) => {
    try {
        let mdfd = new Date(modifiedDateString).getTime();
        return `${fId}_${mdfd}`;
    } catch {
        return `${fId}`;
    }
};
export const areAllFlagsTrue = (map: Map<number, { count: number; flag: boolean }>, numbers: number[]): boolean => {
    for (const number of numbers) {
        const item = map.get(number);
        if (!item || !item.flag) {
            return false;
        }
    }
    return true;
};
export const findRange = (numbers: number[], target: number): number[] => {
    numbers.sort((a: number, b: number) => a - b);
    let startIndex = numbers.findIndex((num: number) => num > target);
    let upper = startIndex === -1 ? numbers[numbers.length - 1] : numbers[startIndex];
    let endIndex = numbers.lastIndexOf(target);
    let lower = endIndex !== -1 ? numbers[endIndex] : numbers[startIndex - 1];
    if (lower === undefined || upper === undefined) {
        return [];
    }
    let result = [];
    for (let i = lower; i <= upper; i++) {
        result.push(i - 1);
    }
    if (target === upper) {
        result.push(upper - 1);
    }
    return result;
};
export const getDesiredHeight = (pageDims: gpagedimensions, srcLoc: string) => {
    const A4_ASPECT_RATIO = 297 / 210;
    const originalWidth = pageDims?.width;
    const originalHeight = pageDims?.height;
    const desiredWidth = getDesiredWidth(srcLoc);
    if (originalWidth && originalHeight) {
        const aspectRatio = originalHeight / originalWidth;
        return desiredWidth * aspectRatio;
    }

    return desiredWidth * A4_ASPECT_RATIO;
};
export const getDesiredWidth = (srcLoc: string): number => {
    let widthFactor = 1.9;
    if (srcLoc === "modal" || srcLoc === "inv") {
        // Directly related to components\XplrrPDF\XplrrPDF.module.css - pdfViewC
        let modifierReduct = 1;
        if (srcLoc === "inv") {
            modifierReduct = 1.8;
        }

        if (window.innerWidth > 1800) {
            widthFactor = 1.8 * modifierReduct;
        } else if (window.innerWidth > 1600) {
            widthFactor = 1.6 * modifierReduct;
        } else if (window.innerWidth > 1400) {
            widthFactor = 1.4 * modifierReduct;
        } else if (window.innerWidth > 1200) {
            widthFactor = 1.3 * modifierReduct;
        } else if (window.innerWidth > 1000) {
            widthFactor = 1.2 * modifierReduct;
        } else if (window.innerWidth > 800) {
            widthFactor = 1.13 * modifierReduct;
        } else if (window.innerWidth > 600) {
            widthFactor = 1.08 * modifierReduct;
        } else if (window.innerWidth > 470) {
            widthFactor = 1.1 * modifierReduct;
        } else if (window.innerWidth > 350) {
            widthFactor = 1.11 * modifierReduct;
        } else if (window.innerWidth > 300) {
            widthFactor = 1.15 * modifierReduct;
        } else {
            //Smallest
            widthFactor = 1.15 * modifierReduct;
        }
    } else {
        // "sbs"
        if (window.innerWidth > 800) {
            widthFactor = 1.85;
        } else {
            //Smallest
            widthFactor = 1.85;
        }
    }
    try {
        return window.innerWidth / widthFactor;
    } catch (e) {
        return 800;
    }
};
export const saveEntriesToGlobal = (currentEntries: number[]) => {
    window.globalCurrentVisibleEntries = currentEntries;
};
export const wigglePixel = async () => {
    if (window.globalWigglePixel == 1) {
        return;
    }
    window.globalWigglePixel = 1;
    while (window.globalWigglePixel == 1) {
        await document.getElementById("pdfmainid9")?.scrollBy(1, 0);
        await new Promise(r => setTimeout(r, 100));

        await document.getElementById("pdfmainid9")?.scrollBy(-1, 0);
        await new Promise(r => setTimeout(r, 100));
    }
    window.globalWigglePixel = 0;
};
export const getDesiredWidthDiff = (srcLoc: string): number => {
    let widthFactor = 1.9;
    if (srcLoc === "modal" || srcLoc === "inv") {
        // Directly related to components\XplrrPDF\XplrrPDF.module.css - pdfViewC
        let modifierReduct = 2;

        if (window.innerWidth > 1800) {
            widthFactor = 1.8 * modifierReduct;
        } else if (window.innerWidth > 1600) {
            widthFactor = 1.6 * modifierReduct;
        } else if (window.innerWidth > 1400) {
            widthFactor = 1.4 * modifierReduct;
        } else if (window.innerWidth > 1200) {
            widthFactor = 1.3 * modifierReduct;
        } else if (window.innerWidth > 1000) {
            widthFactor = 1.2 * modifierReduct;
        } else if (window.innerWidth > 800) {
            widthFactor = 1.13 * modifierReduct;
        } else if (window.innerWidth > 600) {
            widthFactor = 1.08 * modifierReduct;
        } else if (window.innerWidth > 470) {
            widthFactor = 1.1 * modifierReduct;
        } else if (window.innerWidth > 350) {
            widthFactor = 1.11 * modifierReduct;
        } else if (window.innerWidth > 300) {
            widthFactor = 1.15 * modifierReduct;
        } else {
            //Smallest
            widthFactor = 1.15 * modifierReduct;
        }
    } else {
        // "sbs"
        if (window.innerWidth > 800) {
            widthFactor = 1.85;
        } else {
            //Smallest
            widthFactor = 1.85;
        }
    }
    try {
        return window.innerWidth / widthFactor;
    } catch (e) {
        return 800;
    }
};
export const getDesiredHeightDiff = (pageDims: gpagedimensions, srcLoc: string) => {
    const A4_ASPECT_RATIO = 297 / 210;
    const originalWidth = pageDims?.width;
    const originalHeight = pageDims?.height;
    const desiredWidth = getDesiredWidthDiff(srcLoc);

    if (originalWidth && originalHeight) {
        const aspectRatio = originalHeight / originalWidth;
        return desiredWidth * aspectRatio;
    }
    return desiredWidth * A4_ASPECT_RATIO;
};
export const areAllFlagsTrueDiff = (numbers: number[], map?: Map<number, { count: number; flag: boolean }>): boolean => {
    for (const number of numbers) {
        const item = map?.get(number);
        if (!item || !item.flag) {
            return false;
        }
    }
    return true;
};
export const removeExtension = (filename: string) => {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
};

export const get_mod_str = (modified: string): string => {
    return new Date(modified).getTime().toString();
};
export const getCachedImage = (file: gfile) => {
    const modString = new Date(file.modified).getTime().toString();
    const cacheKey = `${file.fileid}_${modString}`;
    console.log("cacheKey", cacheKey);
    return localStorage.getItem(cacheKey);
};

export const cacheImage = (file: gfile, imageUrl: string) => {
    const modString = new Date(file.modified).getTime().toString();
    const cacheKey = `${file.fileid}_${modString}`;
    localStorage.setItem(cacheKey, imageUrl);
};

export const getThumbnail = (file: gfile, selectedGroup: gselectedgroup) => {
    const cachedImage = getCachedImage(file);
    const params = new URLSearchParams({
        of: file.ownerid,
        fid: file.fileid,
        cid: selectedGroup.companyid,
        mod: get_mod_str(file.modified)
    });
    const url = cachedImage || `/qthumb?${params.toString()}`;
    if (!cachedImage) {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (reader.result && (reader.result as string).startsWith("data:image")) {
                        cacheImage(file, reader.result as string);
                    }
                };
                reader.readAsDataURL(blob);
            })
            .catch(e => {
                console.error("Error fetching thumbnail", e);
            });
    }
    return url;
};
export const fetchThumbnail = async (file: gfile, selectedGroup: gselectedgroup) => {
    const params = new URLSearchParams({
        of: file.ownerid,
        fid: file.fileid,
        cid: selectedGroup.companyid,
        mod: new Date(file.modified).getTime().toString()
    });
    const url = `/qthumb?${params.toString()}`;
    try {
        const response = await fetch(url);
        if (response.ok) {
            const blob = await response.blob();
            return URL.createObjectURL(blob);
        }
    } catch (e) {
        console.error("Error fetching thumbnail", e);
    }
    return lines_placeholder;
};
