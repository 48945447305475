import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";

import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";
import { DocumentCard, DocumentCardTitle, IDocumentCardPreviewImage, DocumentCardPreview, IDocumentCardPreviewStyles } from "@fluentui/react/lib/DocumentCard";
import MarkdownIt from "markdown-it";
import { ImgMapKeys, imgMap } from "../../lsts";
import { gselectedgroup } from "../../interfaces";
const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    breaks: true
})
    .enable("table")
    .enable("strikethrough")
    .disable("code");

interface hiMess {
    content: string;
    selectedGroup: gselectedgroup;
}

const removeNewLinesInHtml = (htmlString: string) => {
    const parts = htmlString.split(/(<div\b[^>]*>)/); // Split by <div> tags, keeping <div> tags in the result

    // Leave the first part alone, and remove new lines from all subsequent parts
    const result = parts
        .map((part, index) => {
            // Remove new lines only from content after the first <div>
            if (index > 1) {
                return part.replace(/\n/g, " ");
            }
            return part;
        })
        .join(""); // Reassemble the full string

    return result;
};

export const AnswerHi = ({ content, selectedGroup }: hiMess) => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });
    const sanitizedAnswerHtml = md.render(removeNewLinesInHtml(content)); //DOMPurify.sanitize(content);

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.answerContainerHI} verticalAlign="space-between">
                <AnswerIcon />
                <DocumentCardPreview
                    {...{
                        previewImages: [
                            {
                                name: "Assistant",
                                previewImageSrc: selectedGroup?.headerimageurl || imgMap[(selectedGroup?.assistanttype ?? "anya") as ImgMapKeys],
                                imageFit: 2,
                                height: 120
                            } as IDocumentCardPreviewImage
                        ]
                    }}
                    className={styles.assistantHiMessageImage}
                />
                <Stack.Item grow>
                    <div className={styles.answerHi} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                </Stack.Item>
            </Stack>
        </animated.div>
    );
};
