import { DefaultButton } from "@fluentui/react";
//import { useMsal } from "@azure/msal-react";
import { useMsal } from "../../MsalContext";
import { getRedirectUri, authMS } from "../../authConfig";
import React, { useContext } from "react";
import styles from "./LoginButton.module.css";
import { Icon } from "@fluentui/react";

import { loginRequest } from "../../authConfig";
import { PublicClientApplication, EventType, AccountInfo } from "@azure/msal-browser";

import { GlobalContext } from "../../GlobalContext";

interface LoginButtonProps {
    authorityURI: string;
    text: string;
    imgSrc: string;
    onAction: (resp: any) => void;
    isIFrame: boolean;
}

//export const LoginButton = () => {
export const LoginButton: React.FC<LoginButtonProps> = ({ authorityURI, text, imgSrc, onAction, isIFrame }) => {
    const { msalInstance, updateAuthority } = useMsal();

    const handleRedirectLogin = () => {
        if (msalInstance === null) {
            import.meta.env.DEV === true && console.log("LOGIN_BUT¦msalInstance⇒NULL", authorityURI);
            return;
        }
        const activeAccount = msalInstance.getActiveAccount();
        const isLoggedIn = activeAccount != null;
        localStorage.setItem("msalAuth4", authorityURI);

        import.meta.env.DEV === true && console.log("LOGIN_BUT¦hndlLgnPpp⇒LOCAL_USER_FLOW_CACHE", authorityURI);
        if (msalInstance.getConfiguration().auth.authority != authorityURI) {
            import.meta.env.DEV === true && console.log("LOGIN_BUT¦hndlLgnPpp⇒UPDATE_LOCAL_USER_FLOW_CACHE", authorityURI);
            updateAuthority(authorityURI);
        }
        import.meta.env.DEV === true && console.log("LOGIN_BUT¦hndlLgnPpp⇒acquireTokenPopup", authorityURI);

        msalInstance.acquireTokenRedirect({
            scopes: loginRequest.scopes,
            authority: authorityURI,
            redirectUri: getRedirectUri()
        });
    };

    const handleLoginPopup = () => {
        if (msalInstance === null) {
            import.meta.env.DEV === true && console.log("LOGIN_BUT¦msalInstance⇒NULL", authorityURI);
            return;
        }
        const activeAccount = msalInstance.getActiveAccount();
        const isLoggedIn = activeAccount != null;
        localStorage.setItem("msalAuth4", authorityURI);

        import.meta.env.DEV === true && console.log("LOGIN_BUT¦hndlLgnPpp⇒LOCAL_USER_FLOW_CACHE", authorityURI);
        if (msalInstance.getConfiguration().auth.authority != authorityURI) {
            import.meta.env.DEV === true && console.log("LOGIN_BUT¦hndlLgnPpp⇒UPDATE_LOCAL_USER_FLOW_CACHE", authorityURI);
            updateAuthority(authorityURI);
        }
        import.meta.env.DEV === true && console.log("LOGIN_BUT¦hndlLgnPpp⇒acquireTokenPopup", authorityURI);

        msalInstance
            .acquireTokenPopup({
                scopes: loginRequest.scopes,
                authority: authorityURI,
                redirectUri: getRedirectUri()
            })
            .then(response => {
                import.meta.env.DEV === true && console.log("LOGIN_BUT¦hndlLgnPpp⇒RESPONSE", authorityURI, response);
                onAction(response);
            })
            .catch(error => {
                import.meta.env.DEV === true && console.log("LOGIN_BUT¦hndlLgnPpp¦ERR", error);
                if (error.name === "BrowserAuthError") {
                    import.meta.env.DEV === true && console.log("LOGIN_BUT¦ERR¦NAME⇒BrowserAuthError¦ERR⇒", error);
                    if (error.errorCode === "interaction_in_progress") import.meta.env.DEV === true && console.log("LOGIN_BUT¦ERROR_CODE⇒popup_window_error");
                    /*
                            msalInstance.acquireTokenRedirect({
                                scopes: loginRequest.scopes,
                                authority: authorityURI,
                                redirectUri: getRedirectUri()
                            });
                    */
                }
            });
    };

    return (
        <div className={`${styles.signinbuttons}`}>
            <button
                className={`${styles.signin} ${styles.microsoft}`}
                onClick={() => {
                    if (isIFrame == true) {
                        handleRedirectLogin();
                    } else {
                        handleLoginPopup();
                    }
                }}
            >
                <img src={imgSrc} alt="{text}"></img>
                {text}
            </button>
        </div>
    );
};
