import { gfile, gcontentlist, gpagemap, gselectedgroup, gloggedinuser, gtranscriptentry, gdiffresponse } from "../../interfaces";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import {
    IconButton,
    IDropdownOption,
    TooltipHost,
    DirectionalHint,
    mergeStyles,
    ITextFieldStyles,
    IStyle,
    IDetailsHeaderProps,
    Sticky,
    StickyPositionType,
    DetailsHeader
} from "@fluentui/react";
import { IColumn, IDetailsRowStyles } from "@fluentui/react/lib/DetailsList";

import styles from "./detailedList.module.css";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import { BiError } from "react-icons/bi";
import { RiCheckboxMultipleBlankLine } from "react-icons/ri";

export function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
export function _copyAndSortAdv<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    return items.slice(0).sort((a: T, b: T) => {
        // Check validation conditions
        const key = columnKey as keyof T;
        const aIsValid =
            ((a as any).invoicedataobj?.["grand_total_mismatch"] || 0) != true &&
            ((a as any).invoicedataobj?.["insufficient_totals_data"] || 0) != true &&
            ((a as any).invoicedataobj?.["items_with_insufficient_data"] || 0) < 1 &&
            ((a as any).invoicedataobj?.["items_with_mismatches"] || 0) < 1;

        const bIsValid =
            ((b as any).invoicedataobj?.["grand_total_mismatch"] || 0) != true &&
            ((b as any).invoicedataobj?.["insufficient_totals_data"] || 0) != true &&
            ((b as any).invoicedataobj?.["items_with_insufficient_data"] || 0) < 1 &&
            ((b as any).invoicedataobj?.["items_with_mismatches"] || 0) < 1;

        // If validation status differs, invalid ones go to bottom
        if (aIsValid !== bIsValid) {
            if (isSortedDescending) {
                return aIsValid ? 1 : -1;
            } else {
                return aIsValid ? -1 : 1;
            }
        } else {
            // If both valid, sort by column key
            const key = columnKey as keyof T;
            return (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1;
        }
    });
}
export function _copyAndSortAdvTotal<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    let cKkey = "";
    if (columnKey == "kgnf_total") {
        cKkey = "invoice_total";
    } else {
        cKkey = "invoice_vat";
    }
    return items
        .slice(0)
        .sort((a: T, b: T) =>
            (
                isSortedDescending
                    ? parseFloat((a as any).invoicedataobj?.[cKkey] || 0) < parseFloat((b as any).invoicedataobj?.[cKkey] || 0)
                    : parseFloat((a as any).invoicedataobj?.[cKkey] || 0) > parseFloat((b as any).invoicedataobj?.[cKkey] || 0)
            )
                ? 1
                : -1
        );
}
export function _copyAndSortInvStr<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    let cKkey = "";
    if (columnKey == "kgnf_contractor") {
        cKkey = "contractor_name";
    } else {
        cKkey = "customer_name";
    }
    return items
        .slice(0)
        .sort((a: T, b: T) =>
            (
                isSortedDescending
                    ? (a as any).invoicedataobj?.[cKkey] < (b as any).invoicedataobj?.[cKkey]
                    : (a as any).invoicedataobj?.[cKkey] > (b as any).invoicedataobj?.[cKkey]
            )
                ? 1
                : -1
        );
}
export function parseDocuments(
    documents: {
        fileid: string;
        name: string;
        size: string;
        full_path: string;
        modified: string;
        groupids: string;
        groupnames: string;
        filerole: string;
        ownerid: string;
        filepermlevel: number;
        contentlist: gcontentlist[];
        pagemap: gpagemap;
        sourcetype: string;
        sourceurl: string;
        docstatus: string;
        invoicedata?: string;
        doc_category: string;
        transcription: gtranscriptentry[];
        transcriptionkey: number;
        transcriptname: string;
        diffids: number[];
        approved: number;
    }[]
) {
    const items: IDocument[] = [];
    for (let i = 0; i < documents.length; i++) {
        const randomDate = documents[i].modified;
        const randomFileSize = `${bytesToMB(parseInt(documents[i].size))}`;

        let randomFileType = getFileIcon(13); // PDF

        let fileName = documents[i].name;
        let fileid = documents[i].fileid;

        let displayName = documents[i].name;
        try {
            if (documents[i].sourcetype == "youtube") {
                if (documents[i].docstatus == "Ready") {
                    if (documents[i].name != undefined && documents[i]?.name != null) {
                        displayName = documents[i]?.name?.split("_").slice(2).join("_").replace(".pdf", "");
                    } else {
                        displayName = "No Name";
                    }
                } else {
                    displayName = documents[i].name;
                }
            } else if (documents[i].sourcetype == "web") {
                displayName = documents[i].name.replace(".pdf", "");
            }
        } catch {
            import.meta.env.DEV === true && console.log("errr_doc_processing", documents[i]);
        }

        items.push({
            key: fileid,
            name: fileName,
            value: fileName,
            iconName: randomFileType.url,
            fileType: randomFileType.docType,
            modifiedBy: "",
            dateModified: randomDate,
            dateModifiedValue: documents[i].modified ? new Date(documents[i].modified).getTime() : new Date(1900, 0, 1).getTime(),
            fileSize: randomFileSize,
            fileSizeRaw: 0,
            full_path: documents[i].full_path,
            groupIds: documents[i]?.groupids?.split(","),
            groupNames: documents[i]?.groupnames?.split(","),
            filerole: documents[i].filerole,
            ownerid: documents[i].ownerid,
            filepermlevel: documents[i].filepermlevel,
            contentlist: documents[i].contentlist,
            pagemap: documents[i].pagemap,
            sourcetype: documents[i].sourcetype,
            sourceurl: documents[i].sourceurl,
            docstatus: documents[i].docstatus,
            displayName: displayName,
            invoicedata: documents[i].invoicedata,
            doc_category: documents[i].doc_category,
            invoicedataobj: documents[i].invoicedata ? JSON.parse(documents[i].invoicedata || "{}") : undefined,
            transcription: documents[i].transcription,
            transcriptionkey: documents[i].transcriptionkey,
            transcriptname: documents[i].transcriptname,
            diffids: documents[i].diffids || [],
            approved: documents[i].approved
        });
    }

    return items;
}
export function formatDateTime(dateString: string) {
    const date = new Date(dateString);
    return date.toISOString().replace(/T/, " ").replace(/\..+/, "").replace(/-/g, ".");
}
export function getFileIcon(type: number): { docType: string; url: string } {
    const docType: string = FILE_ICONS[type].name;
    return {
        docType,
        url: `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${docType}.svg`
    };
}
export function bytesToMB(bytes: number) {
    const megabytes = bytes / 1000000;
    return `${megabytes.toFixed(1)} MB`;
}
export const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        font: "normal normal medium 20px/24px Urbanist,Roboto;",
        letterSpacing: "0px",
        color: "#333333"
    },
    fileIconCell: {
        textAlign: "center",
        selectors: {
            "&:before": {
                content: ".",
                display: "inline-block",
                verticalAlign: "middle",
                height: "100%",
                width: "0px",
                visibility: "hidden"
            }
        }
    },
    fileIconImg: {
        verticalAlign: "middle",
        maxHeight: "16px",
        maxWidth: "16px"
    },
    controlWrapper: {
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#f0f0f0",

        borderRadius: "5px"
    },
    exampleToggle: {
        display: "inline-block",
        marginBottom: "10px",
        marginRight: "30px"
    },
    selectionDetails: {
        marginBottom: "20px"
    },
    pdfV: {
        height: "auto"
    },
    shimmerOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "40vh",
        background: "linear-gradient(to bottom, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 100%)",
        zIndex: 1,
        pointerEvents: "none"
    }
});
export const pdfI = <img src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/pdf.svg`} className={classNames.fileIconImg} />;

export const FILE_ICONS: { name: string }[] = [
    { name: "accdb" },
    { name: "audio" },
    { name: "code" },
    { name: "csv" },
    { name: "docx" },
    { name: "dotx" },
    { name: "mpp" },
    { name: "mpt" },
    { name: "model" },
    { name: "one" },
    { name: "onetoc" },
    { name: "potx" },
    { name: "ppsx" },
    { name: "pdf" },
    { name: "photo" },
    { name: "pptx" },
    { name: "presentation" },
    { name: "potx" },
    { name: "pub" },
    { name: "rtf" },
    { name: "spreadsheet" },
    { name: "txt" },
    { name: "vector" },
    { name: "vsdx" },
    { name: "vssx" },
    { name: "vstx" },
    { name: "xlsx" },
    { name: "xltx" },
    { name: "xsn" }
];
export interface IDetailsListDocumentsExampleProps {
    fileList: gfile[] | undefined;
    selectionPass: string;
    selectedGroup: gselectedgroup;
    onSelectionChange: (newSelection: { name: string; groupIds: string; groupNames: string }[]) => void;
    groupList: IDropdownOption[];
    updateCatsState: (newVal: string) => void;
    fileSpaceChangeTrigger: (timestamp: string) => void;
    getOFromListVal: number;
    openSpacesEditPanel: () => void;
    openSpacesAnalyticsPanel: () => void;
    user: { userDisplayName: string; userId: string; userMail: string };
    loggedInUser: gloggedinuser;
    onDelFile: () => void;
    onCitationClicked: (filePath: string, integratedCitation: string | null) => void;
    onCompareClicked: (filePath1: string, filePath2: string, diff: gdiffresponse[]) => void;
    companyId: string;
    listRefreshRequest: () => void;
}
export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    items: IDocument[];
    selectionDetails: string;
    isModalSelection?: boolean;
    isCompactMode?: boolean;
    announcedMessage?: string;
    itemSelectionString?: string;
    pathSelectionString?: string[];
    isModalOpen: boolean;
    isSecurityModalOpen: boolean;
    isContextMenuVisible: boolean;
    isSpaceFilterVisible: boolean;
    contextMenuTarget: any; // Right Click Context Menu Target
    isWorkingOnDelete: boolean;
    spacesFilter: string;
    activeFile: any;
    hasDeleted: boolean;
    invoiceModalItem: IDocument | undefined;
    summaryModalItem: any[] | undefined;
    targetItem: any | undefined;
    callOutVisibilityBool: boolean;
    aiSearchData: any | undefined;
    isMicModalVisible?: boolean;
    highlightItemId?: string;
    sortedColumnKey?: string;
}
export interface IDocument {
    key: string;
    name: string;
    value: string;
    iconName: string;
    fileType: string;
    modifiedBy: string;
    dateModified: string;
    dateModifiedValue: number;
    fileSize: string;
    fileSizeRaw: number;
    full_path: string;
    groupNames: string[];
    groupIds: string[];
    filerole: string;
    ownerid: string;
    filepermlevel: number;
    contentlist: gcontentlist[];
    pagemap: gpagemap;
    isDeleting?: boolean;
    sourcetype: string;
    sourceurl: string;
    docstatus: string;
    displayName: string;
    invoicedata?: string;
    doc_category: string;
    invoicedataobj?: any;
    transcription?: gtranscriptentry[];
    transcriptionkey?: number;
    transcriptname?: string;
    diffids: number[];
    approved?: number;
}
export const headerContainer = mergeStyles({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "#f0f0f0"
});
export const textFieldStyles: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    prefix: {
        backgroundColor: "transparent"
    },
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        borderRadius: "5px",
        border: "1px solid #E1E4E7",
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #333"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
export const controlStyles = {
    root: {
        margin: "0",
        paddingTop: "0px !important",
        width: "100%",
        maxWidth: "100vw",
        overflowY: "scroll"
    },
    commandButton: {
        marginRight: "20px",
        marginBottom: "20px"
    },
    headerWrapper: {
        position: "sticky",
        top: 0,
        zIndex: 1
    } as IStyle,
    detailsHeader: { padding: "0px" }
};
export const customStylesDeLi: Partial<IDetailsRowStyles> = {
    root: {
        selectors: {
            "&:hover": {
                background: "transparent"
            }
        }
    },
    cell: {
        opacity: 0.5,
        pointerEvents: "none"
    }
};
export const actionButtonStyles = {
    iconHovered: { color: "#333333" }
};
export const tooltipGrandTotalMismatchContent = (item: any) => (
    <div className={styles.toolGrandTotalMismatchContainer}>
        <h3 style={{ fontFamily: "Urbanist" }}>
            Mismatched Total by {Math.abs(item.invoicedataobj["invoice_total"] - item.invoicedataobj["calculated_invoice_items_total"]).toFixed(2)}
        </h3>

        <div key={9} className={styles.toolGrandTotalMismatchDiv}>
            <ul className={styles.toolGrandTotalMismatchUl}>
                <div>Recognized Grand Total:</div> <div>{item?.invoicedataobj?.["invoice_total"] ?? 0}</div>
            </ul>
            <ul className={styles.toolGrandTotalMismatchUl}>
                <div>Calculated Grand Total:</div> <div>{item.invoicedataobj["calculated_invoice_items_total"]}</div>
            </ul>
        </div>
        <div className={styles.toolGrandTotalMismatchInfo}>
            <b>Recognized Grand Total</b> is the value AI recognized as the Total of the Invoice
        </div>
        <div className={styles.toolGrandTotalMismatchInfoTwo}>
            <b>Calculated Grand Total</b> is the sum of the recognized Invoice Items
        </div>
    </div>
);
export const tooltipGrandTotalMismatchContentLessThen = (item: any) => (
    <div className={styles.toolGrandTotalMismatchContainer}>
        <h3 style={{ fontFamily: "Urbanist" }}>
            Mismatched Total by {Math.abs(item.invoicedataobj["invoice_total"] - item.invoicedataobj["calculated_invoice_items_total"]).toFixed(2)}
        </h3>

        <div key={9} className={styles.toolGrandTotalMismatchDiv}>
            <ul className={styles.toolGrandTotalMismatchUl}>
                <div>Recognized Grand Total:</div> <div>{item?.invoicedataobj?.["invoice_total"] ?? 0}</div>
            </ul>
            <ul className={styles.toolGrandTotalMismatchUl}>
                <div>Calculated Grand Total:</div> <div>{item.invoicedataobj["calculated_invoice_items_total"]}</div>
            </ul>
        </div>
        <div className={styles.toolGrandTotalMismatchInfo}>Indicates mismatch between AI recognized and calculated grand total.</div>
        <div className={styles.toolGrandTotalMismatchInfo}>
            <b>Recognized Grand Total</b> is the value AI recognized as the Total of the Invoice
        </div>
        <div className={styles.toolGrandTotalMismatchInfoTwo}>
            <b>Calculated Grand Total</b> is the sum of the recognized Invoice Items
        </div>
    </div>
);
export const tooltipInsufficientContent = (item: any) => <div className={styles.toolGrandTotalMismatchContainer}>{"Insufficient Grand Total Data"}</div>;

export const tooltipItemMismatchContent = (item: any) => (
    <div className={styles.tooltipItemMismatchContainer} style={{ padding: "8px", maxWidth: "300px" }}>
        <h3 style={{ fontFamily: "Urbanist", marginBottom: "5px" }}>Invoice Total OK: {item.invoicedataobj["invoice_total"]}</h3>
        <h3 style={{ fontFamily: "Urbanist" }}>Mismatched Items </h3>
        {item.invoicedataobj["items_with_mismatches"].map((item: any, index: number) => (
            <div key={index} className={styles.tooltipItemMismatchItem}>
                <p className={styles.tooltipItemMismatchP}>Item Code: {item.item_code || "Item"}</p>
                <ul className={styles.tooltipItemMismatchUl}>
                    <li className={styles.tooltipItemMismatchLi}>
                        <span className={styles.tooltipItemMismatchSp}>Item Recognized Price: </span>
                        {item.total_price}
                    </li>
                    <li>
                        <span className={styles.tooltipItemMismatchSp}>Item Calculated Price: </span>
                        {item.calculated_total_price}
                    </li>
                </ul>
            </div>
        ))}
        <div className={styles.toolGrandTotalMismatchInfoItems}>
            Indicates mismatches on item level (product quantity by price doesn't match recognized item total)
        </div>
    </div>
);
export const tooltipStyles = {
    root: {},
    content: {
        padding: "-20px"
    }
};
export const invoiceValidColumn = (
    item: IDocument,
    selectedGroup: gselectedgroup,
    isWorkingOnDelete: boolean,
    handleInvoiceExtractionButtonClick: (item: IDocument[]) => void,
    handleDocumentDisplay: (item: IDocument) => void
) => {
    return (
        selectedGroup.permissionlevel >= 1 &&
        (item.invoicedata != undefined && item.invoicedata != "" ? (
            item.docstatus != "Ready" ? (
                <div style={{ margin: "0px 0px 0px -10px", padding: "0", maxWidth: "50px" }}>
                    <Spinner size={SpinnerSize.small} ariaLive="assertive" />
                </div>
            ) : (
                <div
                    style={{ display: "flex", margin: "2px 0px 0px 10px", padding: "0", maxWidth: "50px", cursor: "pointer" }}
                    onClick={() => {
                        handleDocumentDisplay(item);
                    }}
                >
                    {item?.invoicedataobj?.["grand_total_mismatch"] == false &&
                    item?.invoicedataobj?.["insufficient_totals_data"] == false &&
                    item?.invoicedataobj?.["items_with_insufficient_data"] == 0 &&
                    item?.invoicedataobj?.["items_with_mismatches"] == 0 ? (
                        // Valid
                        <div className={styles.validColContainer}>
                            <FaRegCheckCircle size={15} color="#4bbc8e" />
                        </div>
                    ) : item?.invoicedataobj?.["grand_total_mismatch"] == true ? (
                        // Grand Total Mismatch less then 2
                        Math.abs(item.invoicedataobj["invoice_total"] - item.invoicedataobj["calculated_invoice_items_total"]) < 2 ? (
                            <TooltipHost
                                content={tooltipGrandTotalMismatchContentLessThen(item)}
                                directionalHint={DirectionalHint.bottomCenter}
                                styles={tooltipStyles}
                            >
                                <div className={styles.validColContainer}>
                                    <FaRegCheckCircle size={15} color="#ffa65b" />
                                    <div className={styles.validErrLable} title={`Grand Total Mismatch`}></div>
                                </div>
                            </TooltipHost>
                        ) : (
                            // Grand Total Mismatch
                            <TooltipHost content={tooltipGrandTotalMismatchContent(item)} directionalHint={DirectionalHint.topCenter} styles={tooltipStyles}>
                                <div className={styles.validColContainer}>
                                    <MdErrorOutline size={15} color="#cb4788" />
                                    <div className={styles.validErrLable} title={`Grand Total Mismatch`}></div>
                                </div>
                            </TooltipHost>
                        )
                    ) : item?.invoicedataobj?.["insufficient_totals_data"] == true ? (
                        // Insufficient Grand Total Data
                        <TooltipHost content={tooltipInsufficientContent(item)} directionalHint={DirectionalHint.topCenter} styles={tooltipStyles}>
                            <div className={styles.invalidColContainer}>
                                <MdErrorOutline size={15} color="#cb4788" />
                                <div className={styles.validErrLable} title={`Insufficient Grand Total Data`}></div>
                            </div>
                        </TooltipHost>
                    ) : item?.invoicedataobj?.["items_with_insufficient_data"]?.length > 0 ? (
                        // Insufficient Items Data
                        <div className={styles.invalidColContainer} title={`Insufficient Items Data ${item.invoicedataobj["items_with_insufficient_data"]}`}>
                            <TooltipHost content={tooltipItemMismatchContent(item)} directionalHint={DirectionalHint.topCenter} styles={tooltipStyles}>
                                <div className={styles.validColContainer}>
                                    <div style={{ marginTop: "-1px" }}>
                                        <BiError size={17} color="#ffca80" />
                                    </div>
                                    <div className={styles.validErrLable}>Items</div>
                                </div>
                            </TooltipHost>
                        </div>
                    ) : item?.invoicedataobj?.["items_with_mismatches"]?.length > 0 ? (
                        // Items Mismatch
                        <TooltipHost content={tooltipItemMismatchContent(item)} directionalHint={DirectionalHint.topCenter} styles={tooltipStyles}>
                            <div className={styles.validColContainer}>
                                <div style={{ marginTop: "-1px" }}>
                                    <BiError size={17} color="#ffca80" />
                                </div>

                                <div className={styles.validErrLable}></div>
                            </div>
                        </TooltipHost>
                    ) : null}
                    {item?.invoicedataobj?.["multiple_invoice_suspicion"] == true && (
                        <TooltipHost
                            content="Multiple Invoices Suspected [only first extracted]"
                            directionalHint={DirectionalHint.topCenter}
                            styles={tooltipStyles}
                        >
                            <div className={styles.validColContainer}>
                                <MdErrorOutline size={15} color="#cb4788" />
                            </div>
                        </TooltipHost>
                    )}
                    {item?.invoicedataobj?.["duplicate_invoice_suspicion"] == true && (
                        <TooltipHost
                            content="Duplicate Invoice Suspected [only first extracted]"
                            directionalHint={DirectionalHint.topCenter}
                            styles={tooltipStyles}
                        >
                            <div className={styles.otherValidationIcons}>
                                <RiCheckboxMultipleBlankLine size={17} color="#cb4788" />
                            </div>
                        </TooltipHost>
                    )}
                </div>
            )
        ) : selectedGroup.permissionlevel >= 2 ? (
            item.docstatus == "Ready" && (item.doc_category == "invoice" || item.doc_category == "form") ? (
                <IconButton
                    iconProps={{
                        iconName: "Generate",
                        styles: { root: { height: "10px", padding: "0", margin: "-5px 0px 0px 0px", color: "rgb(241, 100, 30)" } }
                    }}
                    title="Extract Data"
                    styles={{ icon: { fontSize: "15px" }, root: { height: "25px", padding: "0", margin: "-5px 0px 0px 0px" } }}
                    ariaLabel="Extract Data"
                    disabled={isWorkingOnDelete}
                    onClick={async () => {
                        await handleInvoiceExtractionButtonClick([item]);
                    }}
                />
            ) : (
                <div style={{ margin: "0px 0px 0px -10px", padding: "0", maxWidth: "50px" }}></div>
            )
        ) : null)
    );
};
export const onRenderDetailsHeader = (props: IDetailsHeaderProps, defaultRender?: (props: IDetailsHeaderProps) => JSX.Element) => {
    const customStyles = {
        root: {
            padding: "0",
            font: "normal normal 13px/40px Urbanist,Roboto !important"
        }
    };
    return (
        <Sticky stickyPosition={StickyPositionType.Header}>
            <DetailsHeader {...props} styles={customStyles} />
        </Sticky>
    );
};
