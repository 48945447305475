import { ReactElement, useMemo } from "react";
import { gdiffparagraph, gpagedimensions } from "../../interfaces";
import { TooltipHost, DirectionalHint } from "@fluentui/react";
/**
 * Describes one top-level object in the array (identified by `fid`).
 */

interface ParagraphOverlayProps {
    pagedimensions: gpagedimensions[];
    docJson?: gdiffparagraph[];
    pageNumber: number;
    pageWidth: number;
    pageHeight: number;
}
const INCHES_TO_POINTS = 72;

export const ParagraphOverlayDIFF = ({ pagedimensions, docJson, pageNumber, pageWidth, pageHeight }: ParagraphOverlayProps): ReactElement | null => {
    if (!docJson || docJson.length < 1) return null;
    const paragraphs = useMemo(() => {
        return docJson;
    }, [docJson]);

    const x_scale = useMemo(() => {
        return pageWidth / pagedimensions[pageNumber].width;
    }, [pageWidth, pagedimensions]);
    const y_scale = pageHeight / pagedimensions[pageNumber].height;

    return (
        <div data-testz="zxc">
            {paragraphs
                ?.filter(para => para.bounding_regions[0]?.page_number == pageNumber + 1)
                .map((prgrph, rIdx) => {
                    const poly = prgrph.bounding_regions[0].polygon || [];
                    const pixelCoordinates = poly.map(point => ({
                        x: point.x * INCHES_TO_POINTS * x_scale,
                        y: point.y * INCHES_TO_POINTS * y_scale
                    }));
                    const xValues = pixelCoordinates.map(point => point.x);
                    const yValues = pixelCoordinates.map(point => point.y);

                    const left = Math.min(...xValues);
                    const right = Math.max(...xValues);
                    const top = Math.min(...yValues);
                    const bottom = Math.max(...yValues);

                    return (
                        <div
                            data-testid="paragraph-overlay"
                            key={`${prgrph.kgnf_id}-${rIdx}`}
                            className={`prg_group_${prgrph.paragraph_group_id}`}
                            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                if (prgrph.are_there_differences != false) {
                                    let paragraphGroup = document.getElementsByClassName(`prg_group_${prgrph.paragraph_group_id}`);
                                    for (let i = 0; i < paragraphGroup.length; i++) {
                                        paragraphGroup[i].classList.toggle("paragraphGroupSelected");
                                    }
                                }
                            }}
                            style={{
                                position: "absolute",
                                left: `${left}px`,
                                top: `${top}px`,
                                width: `${right - left}px`,
                                height: `${bottom - top}px`,
                                border: `${prgrph.are_there_differences ? "1px solid red" : "1px solid green"}`,
                                //backgroundColor: `${prgrph.are_there_differences ? "rgba(255, 0, 0, 0.5)" : "rgba(0, 255, 0, 0.203)"}`,
                                zIndex: 1000
                            }}
                        >
                            <TooltipHost key={`${prgrph.kgnf_id}-${rIdx}`} content={prgrph.diff_overview} directionalHint={DirectionalHint.topCenter}>
                                <div
                                    style={{
                                        left: `${left}px`,
                                        top: `${top}px`,
                                        width: `${right - left}px`,
                                        height: `${bottom - top}px`
                                    }}
                                ></div>
                            </TooltipHost>
                        </div>
                    );
                })}
        </div>
    );
};
