import React, { useState } from "react";
import "./JsonGridGen.css";
import { TbSchema } from "react-icons/tb";
import { TooltipHost, TooltipDelay, DirectionalHint } from "@fluentui/react";
import { toast } from "react-toastify";

interface JsonGridGenProps {
    jsonString?: string;
}

const JsonGridGen: React.FC<JsonGridGenProps> = ({ jsonString }) => {
    const [gridData, setGridData] = useState(jsonString ? JSON.parse(jsonString) : "{}");
    let itmOrd = [
        "invoice_date",
        "contractor_name",
        "contractor_address",
        "contractor_vat_identification",
        "iban",
        "customer_name",
        "customer_address",
        "customer_vat_identification",
        "invoice_total",
        "vat",
        "invoice_currency",
        "item_number",
        "description",
        "item_code",
        "item_measure",
        "quantity",
        "unit_price",
        "total_price",
        "invoice_items"
    ];
    const sortObjectByKeyOrder = (obj: any, keyOrder: string[]) => {
        try {
            return Object.fromEntries(
                Object.entries(obj).sort(([a], [b]) => {
                    return keyOrder.indexOf(a) - keyOrder.indexOf(b);
                })
            );
        } catch (e) {
            console.log("Error in sortObjectByKeyOrder", e);
            return [];
        }
    };

    interface Property {
        name: string;
        description: string;
        type: "string" | "number" | "boolean" | "array" | "object";
        items?: Property[];
    }

    const parseJsonToProperties = (json: any, description = ""): Property[] => {
        const getType = (value: any): "string" | "number" | "boolean" | "array" | "object" => {
            if (Array.isArray(value)) return "array";

            switch (typeof value) {
                case "string":
                    return "string";
                case "number":
                    return "number";
                case "boolean":
                    return "boolean";
                case "object":
                    return "object";
                default:
                    throw new Error("Unsupported type");
            }
        };

        const parseObject = (obj: any, parentDesc: string): Property[] => {
            return Object.entries(obj).map(([key, value]) => {
                const type = getType(value);
                const prop: Property = {
                    name: key,
                    description: `${parentDesc}${key}`,
                    type
                };

                if (type === "object" && value !== null) {
                    prop.items = parseObject(value, `${parentDesc}${key}.`);
                } else if (type === "array" && (value as any).length > 0) {
                    const firstElementType = getType((value as any)[0]);
                    if (firstElementType === "object") {
                        prop.items = parseObject((value as any)[0], `${parentDesc}${key}[].`);
                    }
                }

                return prop;
            });
        };

        return parseObject(json, description);
    };

    const getSchemeIntoClipboard = (json: any) => {
        const properties = parseJsonToProperties(json);

        // Convert the properties array to a formatted JSON string
        const propertiesJson = JSON.stringify(properties, null, 2);

        // Copy to clipboard
        navigator.clipboard
            .writeText(propertiesJson)
            .then(() => {
                console.log("Copied to clipboard!");
                toast.success("Content Scheme copied to clipboard!");
            })
            .catch(error => {
                console.error("Error copying to clipboard: ", error);
            });
    };

    const renderGrid = (data: any, level = 0) => {
        return Object.entries(sortObjectByKeyOrder(data, itmOrd))?.map(([key, value]) => (
            <div key={key} className={`grid-row level-${level}-gen`}>
                <div
                    className="grid-cell key"
                    style={{
                        font: "Urbanist, Roboto",
                        paddingTop: "5px",
                        paddingBottom: "5px"
                    }}
                >
                    {key.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}
                </div>
                <div className="grid-cell value">
                    {(() => {
                        console.log("Value being rendered:", value);
                        return null;
                    })()}
                    {typeof value === "object" && value !== null ? (
                        Array.isArray(value) ? (
                            <div className="array-container">
                                {value.map((item, index) => (
                                    <div key={index} className="array-item">
                                        {typeof item === "object" ? renderGrid(item, level + 1) : <div className="value-link">{String(item)}</div>}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="array-container-roro-gen">{renderGrid(value, level + 1)}</div>
                        )
                    ) : (
                        <div className="value-link">
                            <div
                                onClick={w => {
                                    console.log("AZF¦", value);
                                }}
                            >
                                {String(value)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        ));
    };

    return (
        <div
            style={{
                font: "Urbanist, Roboto",
                padding: "5px",
                borderRadius: "5px",
                margin: "5px"
            }}
        >
            <div
                style={{
                    display: "flex",
                    font: "Urbanist, Roboto",
                    fontSize: "18px",
                    fontWeight: "600",
                    padding: "3px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                    justifyContent: "space-between"
                }}
            >
                <div>Data</div>

                <div
                    onClick={() => {
                        getSchemeIntoClipboard(gridData["data"]);
                    }}
                    className="exportScheme"
                >
                    <TooltipHost
                        closeDelay={500}
                        content="Copy Content Schema. Can be used in Extraction Settings, to define a Document Type"
                        directionalHint={DirectionalHint.topCenter}
                    >
                        <TbSchema />
                    </TooltipHost>
                </div>
            </div>

            {gridData && (
                <div style={{ font: "Urbanist, Roboto" }} className="json-grid">
                    {renderGrid(gridData["data"])}
                </div>
            )}
        </div>
    );
};

export default JsonGridGen;
