import { gloggedinuser, gnewcomp, gselectedgroup, gcustominvoiceinstructions, ginvoicefieldconf } from "../interfaces";

export const qIsG = async (loggedInUser: gloggedinuser) => {
    const url = "/isG";
    const response = await fetch(url, {
        headers: getHeaderG(loggedInUser)
    });
    if (!response.ok) {
        throw Error("Failed to Get G");
    }
    let isG = await response.json();
    return isG;
};
export const qAddComp = async (loggedInUser: gloggedinuser, newComp: gnewcomp) => {
    const response = await fetch("/qAddComp", {
        method: "POST",
        headers: getHeaderG(loggedInUser),
        body: JSON.stringify(newComp)
    });
    if (!response.ok) {
        return response;
    }
    let isG = await response.json();
    return isG;
};
export const qUpdateInvoiceStructuredData = async (loggedInUser: gloggedinuser, jsonData: any, fid: string, groupid: string) => {
    const response = await fetch("/q_update_strutured_json_data", {
        method: "POST",
        headers: getHeaderG(loggedInUser),
        body: JSON.stringify({
            fid: fid,
            json_data: jsonData,
            groupid: groupid
        })
    });
    if (!response.ok) {
        return response;
    }

    let res = await response.json();
    return res;
};
export const qAddInvInstrct = async (loggedInUser: gloggedinuser, tag: gcustominvoiceinstructions) => {
    const response = await fetch("/qAddInvInstrct", {
        method: "POST",
        headers: getHeaderG(loggedInUser),
        body: JSON.stringify({
            groupid: tag.groupid,
            contractorid: tag.contractorid,
            instruction: tag.instruction,
            fieldname: tag.fieldname
        })
    });
    if (!response.ok) {
        return response;
    }
    let res = await response.json();
    return res;
};
export const qGetInvInstrct = async (loggedInUser: gloggedinuser, groupid: string, contractorid: string, fieldname: string) => {
    const params = new URLSearchParams({ groupid: groupid, contractorid: contractorid, fieldname: fieldname });
    const url = "/qGetInvInstrct" + `?${params.toString()}`;
    const response = await fetch(url, {
        method: "GET",
        headers: getHeaderG(loggedInUser)
    });
    if (!response.ok) {
        return {};
    }
    let res = await response.json();
    return res;
};
export const qDelInvInstrct = async (loggedInUser: gloggedinuser, iid: number) => {
    const response = await fetch("/qDelInvInstrct", {
        method: "POST",
        headers: getHeaderG(loggedInUser),
        body: JSON.stringify({
            iid: iid
        })
    });
    if (!response.ok) {
        return response;
    }
    let res = await response.json();
    return res;
};
export const getHeaderG = (loggedInUser: gloggedinuser, token?: any) => {
    if (token) {
        return {
            ...(loggedInUser?.token ? { Authorization: `Bearer ${token.accessToken}` } : {}),
            "X-MS-CLIENT-PRINCIPAL-NAME": getTokenMail(token),
            "X-MS-CLIENT-PRINCIPAL-ID": getTokenAccountID(token),
            "Content-Type": "application/json"
        };
    } else {
        return {
            ...(loggedInUser?.token ? { Authorization: `Bearer ${loggedInUser.token}` } : {}),
            "X-MS-CLIENT-PRINCIPAL-NAME": loggedInUser?.mail ? loggedInUser.mail : "",
            "X-MS-CLIENT-PRINCIPAL-ID": loggedInUser?.userId ? loggedInUser.userId : "",
            "Content-Type": "application/json"
        };
    }
};
const getTokenMail = (token: any) => {
    //import.meta.env.DEV === true && console.log("LYT¦getTokenMail", localStorage.getItem("msalAuth4"), token);
    return token.idTokenClaims?.emails?.length > 0 ? token.idTokenClaims?.emails[0] : token.idTokenClaims?.email;
};
const getTokenAccountID = (token: any) => {
    //import.meta.env.DEV === true && console.log("LYT¦getTokenMail", localStorage.getItem("msalAuth"), token);
    return token.uniqueId || token.localAccountId || token.oid;
};
export const qSaveExtractionFieldList = async (loggedInUser: gloggedinuser, selectedGroup: gselectedgroup, fieldConf: ginvoicefieldconf) => {
    const response = await fetch("/qSaveExtractionFieldList", {
        method: "POST",
        headers: getHeaderG(loggedInUser),
        body: JSON.stringify({
            groupid: selectedGroup.selectionId,
            fieldconf: fieldConf
        })
    });
    if (!response.ok) {
        return response;
    }
    let res = await response.json();
    return res;
};
