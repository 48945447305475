import React, { useState, useEffect, useContext } from "react";
import {
    Stack,
    TextField,
    TooltipHost,
    Checkbox,
    PrimaryButton,
    ITextFieldStyles,
    IStackStyles,
    ICheckboxStyles,
    ISliderStyles,
    DirectionalHint,
    IconButton,
    Slider,
    IDropdownOption,
    Dropdown,
    IDropdownStyles,
    ITextFieldProps,
    IRenderFunction
} from "@fluentui/react";
import { toast } from "react-toastify";
import styles from "./CompEditForm.module.css";
import TagInput from "../TagInput/TagInput";
import { gactivecomp, gselectedgroup } from "../../interfaces";
import { GlobalContext } from "../../GlobalContext"; // Tests¦Context Accross Routes (ex. Chat/Search)

interface ICompEditFormProps {
    selectedGroup: gselectedgroup;
    activeComp: gactivecomp;
}
const CompEditForm: React.FC<ICompEditFormProps> = ({ selectedGroup, activeComp }) => {
    const { loggedInUser } = useContext(GlobalContext);
    const [editedComp, setEditedComp] = useState<gactivecomp>({ ...activeComp });
    const [isLoadingAPI, setIsLoadingAPI] = useState(false);

    useEffect(() => {
        setEditedComp({ ...activeComp });
    }, [activeComp]);

    useEffect(() => {
        setIsLoadingAPI(false);
    }, []);

    const getHeader = () => {
        return {
            ...(loggedInUser?.token ? { Authorization: `Bearer ${loggedInUser.token}` } : {}),
            "X-MS-CLIENT-PRINCIPAL-NAME": loggedInUser?.mail ? loggedInUser.mail : "",
            "X-MS-CLIENT-PRINCIPAL-ID": loggedInUser?.userId ? loggedInUser.userId : "",
            "Content-Type": "application/json"
        };
    };
    const stackStyles: Partial<IStackStyles> = {
        root: { gap: 10 }
    };
    const tooltipStyles = {
        styles: {
            root: {
                borderRadius: "5px"
            },
            beak: {
                left: "20px !important",
                right: "auto !important"
            },
            beakCurtain: {
                borderRadius: "5px"
            },
            calloutMain: {
                borderRadius: "5px"
            }
        }
    };
    const dropdownStyles: Partial<IDropdownStyles> = {
        root: {
            padding: "0",
            margin: "0",
            borderRadius: "5px !important",
            color: "#333",
            borderColor: "#333",
            selectors: {
                ":focus": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":hover": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":active": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":after": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":before": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                }
            }
        },
        label: {
            fontFamily: "Urbanist",
            fontSize: "15px",
            borderRadius: "5px !important",
            borderColor: "#333",
            width: "100%",
            marginBottom: "5px"
        },
        title: {
            fontFamily: "Urbanist",
            borderRadius: "5px !important",
            borderColor: "#333",
            fontSize: "12px",
            height: "30px",
            width: "100%"
        },

        dropdown: {
            borderRadius: "5px !important",
            color: "#333",
            borderColor: "#333",
            selectors: {
                ":focus": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":hover": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":active": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":after": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":before": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                }
            }
        },
        dropdownItem: {
            fontFamily: "Urbanist",
            borderRadius: "5px !important",
            fontSize: "12px",
            height: "30px",
            color: "#333"
        },

        dropdownItemSelected: {
            fontFamily: "Urbanist",
            borderRadius: "5px !important",

            fontSize: "12px",
            height: "30px",
            color: "#333"
        },
        caretDown: {
            borderRadius: "5px !important",
            color: "#333"
        }
    };
    const handleChange = (field: keyof gactivecomp, value: string | number | boolean) => {
        setEditedComp(prev => ({ ...prev, [field]: value }));
    };
    const handleSave = () => {
        setIsLoadingAPI(true);
        fetch("/qEditComp", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                companyid: activeComp.companyid,
                licensingkind: editedComp.licensingkind,
                alloweddomains: editedComp.alloweddomains
            })
        }).then(resp => {
            if (!resp.ok) {
                toast.error("Error Saving Company");
            } else {
                toast.success("Company Saved");
            }
            setIsLoadingAPI(false);
        });
    };
    const compTierOptions: IDropdownOption[] = [
        { key: "0", text: "Credit Card Licensing" },
        { key: "1", text: "Business Licensing" }
    ];
    const onAllowedDomainsChange = (tags: string[]) => {
        setEditedComp(prev => ({ ...prev, alloweddomains: tags?.join(";").toLowerCase() }));
    };
    return (
        <div className={styles.editModalWrap}>
            <Stack styles={stackStyles}>
                <div className={styles.editFormSectionTitle}>
                    <span>General Configuration</span>
                </div>

                <TooltipHost content="Licensing Tier" directionalHint={DirectionalHint.topAutoEdge} calloutProps={tooltipStyles}>
                    <Dropdown
                        label="Licensing Tier"
                        options={compTierOptions}
                        selectedKey={editedComp.licensingkind.toString()}
                        onChange={(_, option) => handleChange("licensingkind", Number(option?.key))}
                        styles={dropdownStyles}
                    />
                </TooltipHost>
                <div className={styles.groupFunc}>
                    <TooltipHost
                        content="Restrict the domains that can access the Assistant. Leave empty to allow all domains."
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        calloutProps={tooltipStyles}
                    >
                        <div className={styles.custLabelTagInput}>
                            <span>Allowed Domains</span>
                        </div>
                    </TooltipHost>
                    <TagInput
                        onChange={onAllowedDomainsChange}
                        palceholderText="Add a Domain and press Enter"
                        initialTags={editedComp.alloweddomains != "" ? editedComp.alloweddomains?.split(";") : []}
                    />
                </div>
                <div className={styles.modalBottomButs}>
                    <PrimaryButton
                        text="Save"
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={isLoadingAPI}
                        className={styles.saveSpaceButton}
                    />
                </div>
            </Stack>
        </div>
    );
};

export default CompEditForm;
