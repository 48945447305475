import React, { useState, useRef, useEffect } from "react";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { getSynth, getVoiceVersion, prepSsml } from "./../../api/aud";
import { createVisGradient } from "./../../api/aud";
interface queItem {
    text: string;
    answerid: string;
}
interface SpeechOutputProps {
    chunk: queItem;
    pauseSpeak: boolean | null;
    isSpeakingCallback: (val: boolean) => void;
    msgCanvasRef?: React.RefObject<HTMLCanvasElement>;
}
const SpeechOutput: React.FC<SpeechOutputProps> = ({ chunk, pauseSpeak, isSpeakingCallback, msgCanvasRef }) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [que, setQue] = useState<queItem[]>([]);
    const [answerIdState, setAnswerIdState] = useState<string>();
    const [voiceVersionState, setVoiceVersionState] = useState<string>("");
    const playrRef = useRef<sdk.SpeakerAudioDestination | null>(null);
    const audConfRef = useRef<sdk.AudioConfig | null>(null);
    const synthRef = useRef<sdk.SpeechSynthesizer | null>(null);
    const pauseSpeakRef = useRef(pauseSpeak);
    const answerIdRef = useRef<string | null>(null);
    const chunkRef = useRef<queItem | null>(null);

    const visAudCtxRef = useRef<AudioContext | null>(null);
    const animFrameRef = useRef<number | null>(null);

    useEffect(() => {
        if (isPlaying == true && pauseSpeak == true) {
            console.log("pauseSpeak_01", pauseSpeak);
            pauseSpeakRef.current = true;
            playrRef.current && playrRef.current.pause();
        } else if (playrRef.current && pauseSpeak == false) {
            console.log("pauseSpeak_02", pauseSpeak);
            setQue(prevQue => (prevQue.length > 0 ? prevQue.slice(1) : []));
            pauseSpeakRef.current = false;
            playrRef.current && playrRef.current.resume();
            setPlayFlags(false);
        } else {
            console.log("pauseSpeak_03", pauseSpeak);
            pauseSpeakRef.current = pauseSpeak;
        }
    }, [pauseSpeak]);

    useEffect(() => {
        if (msgCanvasRef?.current) {
            console.log("msgCanvasRef", msgCanvasRef.current);
            startMsgVis();
        }
    }, [msgCanvasRef]);

    useEffect(() => {
        // If the same chunk with same AnswerID is in Que skip
        if (!chunk.text) return;
        if (que.some(queChunk => queChunk.text === chunk.text && queChunk.answerid === chunk.answerid)) return;

        if (chunkRef.current?.answerid == chunk.answerid && chunkRef.current?.text == chunk.text) {
            console.log("chunk_is_already_current");
            return;
        } else {
            console.log("update_current_chunk", chunk);
            chunkRef.current = chunk;
        }

        setAnswerIdState(chunk.answerid);
        answerIdRef.current = chunk.answerid;

        // If Chunk Answer ID is New, Reset Everything and add New Chunk to Cleared Que
        if (que.length > 0 && !que.map(q => q.answerid).includes(chunk.answerid)) {
            console.log("reset_que");

            synthRef.current?.close();
            synthRef.current = null;

            audConfRef.current?.close();
            audConfRef.current = null;

            playrRef.current?.pause();
            playrRef.current?.close();
            playrRef.current = null;

            setQue([chunk]);

            setPlayFlags(false);
        } else {
            //Add Chunk to Existing AnswerID Que
            console.log("add_chunk_to_que", chunk);

            setQue(prevQue => [...prevQue, chunk]);
        }
    }, [chunk]);

    const setPlayFlags = (isPlay: boolean) => {
        console.log("setPlayFlags", isPlay);
        setIsPlaying(isPlay);
        isSpeakingCallback(isPlay);
    };

    const startMsgVis = () => {
        if (playrRef.current?.internalAudio && msgCanvasRef?.current) {
            console.log("startMsgVis");
            if (visAudCtxRef.current) visAudCtxRef.current.close();
            if (animFrameRef.current) cancelAnimationFrame(animFrameRef.current);

            const audCtx = new AudioContext();
            visAudCtxRef.current = audCtx;

            const analyser = audCtx.createAnalyser();
            analyser.fftSize = 256;
            const dataArray = new Uint8Array(analyser.frequencyBinCount);
            try {
                const source = audCtx.createMediaElementSource(playrRef.current.internalAudio);

                source.connect(analyser);
                analyser.connect(audCtx.destination);
                window.globalCnvsTr = msgCanvasRef?.current;
                let canvasCtx = msgCanvasRef?.current.getContext("2d");
                const cnvsWidth = msgCanvasRef?.current.width;
                const cnvsHeight = msgCanvasRef?.current.height;

                let keyValue = msgCanvasRef?.current?.getAttribute("data-key") || "0";
                function draw() {
                    if (keyValue != msgCanvasRef?.current?.getAttribute("data-key")) {
                        keyValue = msgCanvasRef?.current?.getAttribute("data-key") || "0";
                        canvasCtx = msgCanvasRef?.current?.getContext("2d") || null;
                    }

                    animFrameRef.current = requestAnimationFrame(draw);
                    analyser.getByteFrequencyData(dataArray);
                    if (canvasCtx == null) return;
                    canvasCtx.fillStyle = "rgb(1, 1, 1)";
                    canvasCtx.fillRect(0, 0, 1, 1);
                    canvasCtx.clearRect(0, 0, cnvsWidth, cnvsHeight);
                    const barWidth = (cnvsWidth / analyser.frequencyBinCount) * 2.5;
                    const centerY = cnvsHeight / 2;
                    let barHeight,
                        x = 0;

                    for (let i = 0; i < analyser.frequencyBinCount; i++) {
                        barHeight = dataArray[i] * 0.3;
                        const hue = (i / analyser.frequencyBinCount) * 360; // Hue varies across the spectrum
                        if (msgCanvasRef?.current) {
                            canvasCtx.fillStyle = createVisGradient(canvasCtx, msgCanvasRef?.current, barHeight);
                        } else {
                            canvasCtx.fillStyle = `hsl(${hue}, 100%, 50%)`;
                        }
                        canvasCtx.fillRect(x, centerY - barHeight, barWidth, barHeight); // Top half
                        canvasCtx.fillRect(x, centerY, barWidth, barHeight); // Bottom half

                        x += barWidth + 1;
                    }
                }
                draw();
            } catch (error) {}
        }
    };
    useEffect(() => {
        if (que.length > 0 && isPlaying != true) {
            console.log("que", isPlaying, que);

            let chunkToPlay = que.at(0);
            if (!chunkToPlay) return;

            if (playrRef.current == null) {
                playrRef.current = new sdk.SpeakerAudioDestination();
                audConfRef.current = sdk.AudioConfig.fromSpeakerOutput(playrRef.current);

                playrRef.current.onAudioStart = (sender: sdk.IPlayer) => {
                    console.log("audio_start");
                    startMsgVis();
                };
            }

            let voiceAndLang = getVoiceVersion(chunkToPlay.text);
            if (synthRef.current == null) {
                if (audConfRef.current == null) return;
                setVoiceVersionState(voiceAndLang.voice);
                synthRef.current = getSynth(audConfRef.current, voiceAndLang.voice, voiceAndLang.language);
                if (synthRef.current == null) return;
            }

            setPlayFlags(true);
            synthRef.current.speakSsmlAsync(
                prepSsml(chunkToPlay.text, voiceAndLang.voice, voiceAndLang.language),
                result => {
                    if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                        console.log("speech_synth_cmpltd");
                        setTimeout(
                            () => {
                                console.log("timeout_pre_remove", answerIdRef.current, answerIdState);
                                if (pauseSpeakRef.current != true && answerIdRef.current == answerIdState) {
                                    console.log("timeout_remove");
                                    setQue(prevQue => (prevQue.length > 0 ? prevQue.slice(1) : []));
                                    setPlayFlags(false);
                                }
                            },
                            result.audioDuration / 10000 - 500
                        );
                    } else {
                        console.error("speech_synth_errr", result.errorDetails);
                    }
                },
                error => {
                    console.error("errror" + error);
                }
            );
        }
    }, [que, isPlaying]);

    return <div></div>;
};

export default SpeechOutput;
