import React from "react";
import { Panel, IPanelProps, PanelType } from "@fluentui/react/lib/Panel";
import { List } from "@fluentui/react/lib/List";
import styles from "../../pages/chat/Chat.module.css";
import { ggroup, grecentconvosaccrossspaces } from "../../interfaces";

interface SpacePanelProps {
    isSpacePanelOpen: boolean;
    toggleSpacePanel: () => void;
    groupList: ggroup[];
    selectedGroup: { selectionId: string };
    setGrpTab: () => void;
    handleLeftSpacePanelClick: (item: any, activeConvoKey?: string) => void;
    recentConvosAccrossSpaces: grecentconvosaccrossspaces[];
    activeConvoKey: string;
}
const getFormattedDate = (dateString: string) => {
    // Parse the date string and convert it to the user's local time
    const date = new Date(dateString);

    const formatter = new Intl.DateTimeFormat("default", {
        day: "numeric",
        month: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });

    const parts = formatter.formatToParts(date);
    const partValues: { [key: string]: string } = {};

    parts.forEach(part => {
        partValues[part.type] = part.value;
    });

    const formattedDate = `${partValues.hour}:${partValues.minute} ${partValues.day}/${partValues.month}`;

    return <span>{formattedDate}</span>;
};

const getHiddenSpaces = (groupList: ggroup[]): ggroup[] => {
    return groupList.filter((item: ggroup) => {
        return (localStorage.getItem("hiddenGroups") || "").indexOf(item.groupid) >= 0;
    });
};

const SpacePanel: React.FC<SpacePanelProps> = ({
    isSpacePanelOpen,
    toggleSpacePanel,
    groupList,
    selectedGroup,
    setGrpTab,
    handleLeftSpacePanelClick,
    recentConvosAccrossSpaces,
    activeConvoKey
}) => (
    <Panel
        isOpen={isSpacePanelOpen}
        styles={{ header: { justifyContent: "flex-start" } }}
        onDismiss={toggleSpacePanel}
        isLightDismiss={true}
        closeButtonAriaLabel="Close"
        onRenderHeader={(props: IPanelProps | undefined, defaultRender: ((props?: IPanelProps) => JSX.Element | null) | undefined) => {
            if (!props) {
                return null;
            }
            return (
                <div className={styles.spacePanelHeaderArea}>
                    <div className={styles.spacePanelHeaderArea}>
                        <h3 onClick={setGrpTab}>Your Assistants</h3>
                    </div>
                    {defaultRender ? defaultRender(props) : null}
                </div>
            );
        }}
        type={PanelType.smallFixedNear}
    >
        <div>
            {recentConvosAccrossSpaces?.length > 0 && (
                <div>
                    <div className={styles.spacePanelSecondListHeader}>
                        <h3 onClick={setGrpTab}>Recent</h3>
                    </div>
                    <List
                        items={recentConvosAccrossSpaces}
                        onRenderCell={(item: any, index: any) => (
                            <div key={index} className={styles.spacePanelItem} onClick={() => handleLeftSpacePanelClick(item, item.key)}>
                                <div
                                    className={`${styles.spacePanelLink} ${
                                        item.groupid == selectedGroup.selectionId && item.key == activeConvoKey ? styles.spacePanelLinkActive : ""
                                    }`}
                                >
                                    <div className={styles.spacePanelConvoNameText}>{item.name}</div>
                                    <div className={styles.spacePanelRecentDetailsWrap}>
                                        <div className={styles.spacePanelSpaceNameText}>{item.groupname}</div>{" "}
                                        <div className={styles.spacePanelRecentDateText}>{getFormattedDate(item.lastinteractiontimestamp)}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                </div>
            )}
            <div className={styles.spacePanelSecondListHeader}>
                <h3 onClick={setGrpTab}>All</h3>
            </div>
            <List
                items={groupList.filter((item: ggroup) => {
                    return (localStorage.getItem("hiddenGroups") || "").indexOf(item.groupid) < 0;
                })}
                onRenderCell={(item: any, index: any) => (
                    <div key={index} className={styles.spacePanelItem} onClick={() => handleLeftSpacePanelClick(item)}>
                        <div className={`${styles.spacePanelLink} ${item.groupid === selectedGroup.selectionId ? styles.spacePanelLinkActive : ""}`}>
                            {item.groupname}
                        </div>
                    </div>
                )}
            />
            {getHiddenSpaces(groupList).length > 0 && (
                <div>
                    <div className={styles.spacePanelSecondListHeader}>
                        <h3 onClick={setGrpTab}>Hidden</h3>
                    </div>
                    <List
                        items={getHiddenSpaces(groupList)}
                        onRenderCell={(item: any, index: any) => (
                            <div key={index} className={styles.spacePanelItem} onClick={() => handleLeftSpacePanelClick(item)}>
                                <div className={`${styles.spacePanelLink} ${item.groupid === selectedGroup.selectionId ? styles.spacePanelLinkActive : ""}`}>
                                    {item.groupname}
                                </div>
                            </div>
                        )}
                    />
                </div>
            )}
        </div>
    </Panel>
);

export default SpacePanel;
