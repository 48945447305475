import React, { useState } from "react";
import { Callout, DirectionalHint } from "@fluentui/react";
import { gfile } from "../../interfaces";
import styles from "./FileDropdown.module.css";
import { TbListSearch } from "react-icons/tb";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { FiUpload, FiYoutube } from "react-icons/fi";
import { CgWebsite } from "react-icons/cg";
interface FileDropdownProps {
    file?: gfile;
    fileListStateVar: gfile[];
    handleFileClick: (target: EventTarget, fl: gfile, flIndex: number) => void;
    onContentShow?: (fl: gfile | undefined, flIndex: number | undefined) => void;
    activeFileId?: string;
}
import { pdfI } from "./../detailedList/detailedMdls";
const FileDropdown: React.FC<FileDropdownProps> = ({ file, fileListStateVar, handleFileClick, onContentShow, activeFileId }) => {
    const [menuVisible, setMenuVisible] = useState<boolean>(false);
    const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
    const [isHoveringMenu, setIsHoveringMenu] = useState<boolean>(false);

    const onSpaceFileListMouseClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setTargetElement(event.currentTarget);
        setMenuVisible(true);
    };
    const onItemClick = (event: React.MouseEvent<HTMLDivElement>, fl: gfile, flIndex: number) => {
        handleFileClick(event.target, fl, flIndex);
        setMenuVisible(false);
    };

    return (
        <div className={styles.pdfMoHeadText}>
            <div className={`${(file?.contentlist?.length ?? 0) < 1 ? styles.fileTitleAndIconContainerNoContent : styles.fileTitleAndIconContainer}`}>
                <div
                    className={`${(file?.contentlist?.length ?? 0) < 1 ? styles.fileListIconNoFileContent : styles.fileListIcon}`}
                    onClick={() => {
                        onContentShow && onContentShow(file, parseInt(activeFileId || "0"));
                    }}
                >
                    <div className={`${(file?.contentlist?.length ?? 0) < 1 ? styles.fileListOnlyIconNoContent : styles.fileListOnlyIcon}`}>
                        <TbListSearch size={28} />
                    </div>
                </div>

                <div className={styles.fileTitleText}>{file?.name}</div>
                {/* BUTTON TO SHOW DOCUMENT LIST */}
                <div
                    className={`${fileListStateVar.length < 1 ? styles.fileListDownIconLessThenTwoDocs : styles.fileListDownIcon}`}
                    onClick={onSpaceFileListMouseClick}
                >
                    <MdOutlineKeyboardArrowDown size={28} />
                </div>
            </div>

            <div id="pdfTtldrpTrg" className={styles.targetEl}></div>
            {menuVisible && (
                <Callout
                    target={"#pdfTtldrpTrg"}
                    className={styles.fileListCallout}
                    onDismiss={() => setMenuVisible(false)}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={false}
                >
                    <div className={styles.fileListHeader}>Assistant Content</div>
                    <div className={styles.fileListHeaderSub}></div>
                    <div className={styles.scrollableDocList} style={{ margin: 0, padding: 0, listStyle: "none" }}>
                        {fileListStateVar.map((fl: gfile, flIndex: number) => (
                            <div
                                key={`FL_${flIndex}`}
                                onClick={event => onItemClick(event, fl, flIndex)}
                                className={`${styles.contentFile} ${fl.fileid === activeFileId ? styles.pdfContFileSelected : ""}`}
                                style={{
                                    padding: "8px",
                                    cursor: "pointer",
                                    verticalAlign: "middle",
                                    textAlign: "left",
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                {fl.sourcetype == "youtube" ? <FiYoutube /> : fl.sourcetype == "web" ? <CgWebsite /> : pdfI}
                                <div style={{ width: "3px" }}></div>
                                {fl.name.split(".").slice(0, -1).join(".")}
                            </div>
                        ))}
                    </div>
                </Callout>
            )}
        </div>
    );
};

export default FileDropdown;
