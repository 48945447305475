import React from "react";
import { useRef, useState, useEffect } from "react";
import { DetailsList, IColumn, DetailsListLayoutMode, Selection, SelectionMode, IDetailsRowProps, DetailsRow } from "@fluentui/react/lib/DetailsList";
import styles from "./CompList.module.css";
import { PrimaryButton, Modal } from "@fluentui/react";
import { gloggedinuser, gnewcomp, gcompflat } from "../../interfaces";
import { qIsG } from "../../api/qpi";
import AddCompanyModal from "../AddCompanyModal/AddCompanyModal";
interface ICompListProps {
    comps: gcompflat[];
    newActiveComp: (newActiveComp: gcompflat) => void;
    activeComp: any;
    loggedInUser: gloggedinuser;
}

const columns: IColumn[] = [
    { key: "column1", name: "Id", fieldName: "companyid", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "column2", name: "Company Name", fieldName: "companyname", minWidth: 100, maxWidth: 200, isResizable: true }
];

const CompList: React.FC<ICompListProps> = ({ comps, newActiveComp, activeComp, loggedInUser }) => {
    const [selectedCompany, setSelectedCompany] = useState<any>("");
    const [compsState, setCompsState] = useState<gcompflat[]>(comps);
    const [isG, setIsG] = useState<boolean>(false);
    const [isCreateCompOpen, setIsCreateCompOpen] = useState<boolean>(false);

    useEffect(() => {
        qIsG(loggedInUser).then(res => setIsG(res));
    }, [loggedInUser]);
    const selection = new Selection({
        onSelectionChanged: () => {
            const selectedItem = selection.getSelection()[0] as gcompflat;
            setSelectedCompany(selectedItem ? selectedItem : {});
        },
        selectionMode: SelectionMode.single
    });
    const refreshComps = (newComp: gnewcomp) => {
        setCompsState(prev => [
            ...prev,
            { companyid: newComp.companyid, companyname: newComp.companyname, permissionlevel: 3, licensingkind: newComp.licensingkind, alloweddomains: "" }
        ]);
        setIsCreateCompOpen(false);
        window.location.href = `/#/${newComp.companyid}`;
        window.location.reload();
    };
    const onActiveItemChanged = (item: any) => {
        newActiveComp(item);
    };
    const customStyles = {
        root: { cursor: "pointer" }
    };
    return (
        <div className={styles.contMa}>
            <Modal
                isOpen={isCreateCompOpen}
                onDismiss={() => {
                    setIsCreateCompOpen(false);
                }}
                isBlocking={false}
                containerClassName={styles.modalNewComp}
            >
                <AddCompanyModal refreshComps={refreshComps} comps={comps} loggedInUser={loggedInUser} />
            </Modal>
            <div className={styles.userListHead}>
                {isG && (
                    <PrimaryButton
                        className={styles.changeActiveComp}
                        text="New Company"
                        disabled={false}
                        iconProps={{ iconName: "List" }}
                        onClick={() => {
                            setIsCreateCompOpen(true);
                        }}
                    />
                )}
            </div>
            <DetailsList
                items={compsState}
                columns={columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified} // Adjust layout mode as needed
                selectionPreservedOnEmptyClick={true}
                selection={selection}
                onActiveItemChanged={onActiveItemChanged} // Single click or keyboard interaction
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                onItemInvoked={item => {
                    setSelectedCompany(item);
                    newActiveComp(item);
                }}
                onRenderRow={(prps: IDetailsRowProps | undefined): JSX.Element => {
                    return <DetailsRow {...(prps as IDetailsRowProps)} styles={customStyles} />;
                }}
            />
        </div>
    );
};

export default CompList;
