import { IPublicClientApplication } from "@azure/msal-browser";

interface AppServicesToken {
    id_token: string;
    access_token: string;
    user_claims: Record<string, any>;
}
const entMode = import.meta.env.VITE_LOCAL_ONLY_LOGIN_MODE;

const authClientId = entMode != "True" ? import.meta.env.VITE_AUTHORITY_APP_REG_CLIENT_ID : import.meta.env.VITE_AUTHORITY_APP_REG_CLIENT_ID_LOC;
const authKnownURI = entMode != "True" ? import.meta.env.VITE_KNOWN_AUTHORITY_URI : import.meta.env.VITE_KNOWN_AUTHORITY_URI_LOC;
const authAuthorityLocal = entMode != "True" ? import.meta.env.VITE_AUTHORITY_LOCAL : import.meta.env.VITE_AUTHORITY_LOCAL_LOC;

const authAuthorityGoogle = import.meta.env.VITE_AUTHORITY_GOOGLE;
const authAuthorityMS = import.meta.env.VITE_AUTHORITY_MS;

const authAuthorityPASSRESET = import.meta.env.VITE_AUTHORITY_PASSRESET;

export const authMS = {
    clientId: authClientId, // Mandatory
    authority: authAuthorityMS,
    knownAuthorities: [authKnownURI],
    redirectUri: "/redirect" // Registered URI in App Reg. Defaults to "window.location.href".
};
export const authGOOGLE = {
    clientId: authClientId,
    authority: authAuthorityGoogle,
    knownAuthorities: [authKnownURI],
    redirectUri: "/redirect"
};
export const authLOCAL = {
    clientId: authClientId,
    authority: authAuthorityLocal,
    knownAuthorities: [authKnownURI],
    redirectUri: "/redirect"
};
export const authRESET = {
    clientId: authClientId,
    authority: authAuthorityPASSRESET,
    knownAuthorities: [authKnownURI],
    redirectUri: "/redirect"
};
const authSetup = {
    // Set to true if login elements should be shown in the UI
    useLogin: true,
    msalConfig: {
        auth: {
            clientId: authClientId,
            authority: authAuthorityLocal,
            knownAuthorities: [authKnownURI],
            redirectUri: "/redirect"
        },
        cache: {
            cacheLocation: "localStorage", // Cache location. "sessionStorage" is more secure, "localStorage" gives you SSO between tabs
            storeAuthStateInCookie: true // If you wish to store cache items in cookies as well as browser cache, set to "true".
        }
    },
    loginRequest: {
        scopes: ["openid", "profile", "email", "offline_access"]
    },
    tokenRequest: {
        scopes: ["openid", "profile", "email", "offline_access"]
    },
    requireAccessControl: false,
    enableUnauthenticatedAccess: false
};

export const useLogin = authSetup.useLogin;
export const requireAccessControl = authSetup.requireAccessControl;
export const enableUnauthenticatedAccess = authSetup.enableUnauthenticatedAccess;
export const requireLogin = requireAccessControl && !enableUnauthenticatedAccess;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = authSetup.msalConfig;

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = authSetup.loginRequest;
export const tokenRequest = authSetup.tokenRequest;

// Build an absolute redirect URI using the current window's location and the relative redirect URI from auth setup
export const getRedirectUri = () => {
    return window.location.origin + authSetup.msalConfig.auth.redirectUri;
};

// Get an access token if a user logged in using app services authentication
// Returns null if the app doesn't support app services authentication

// Sign out of app services
// Learn more at https://learn.microsoft.com/azure/app-service/configure-authentication-customize-sign-in-out#sign-out-of-a-session

// Determine if the user is logged in
// The user may have logged in either using the app services login or the on-page login
export const isLoggedIn = (client: IPublicClientApplication | undefined): boolean => {
    return client?.getActiveAccount() != null;
};

// Get an access token for use with the API server.
// ID token received when logging in may not be used for this purpose because it has the incorrect audience
// Use the access token from app services login if available
const getToken = (client: IPublicClientApplication): Promise<any> => {
    return client
        .acquireTokenSilent({
            scopes: tokenRequest.scopes,
            authority: msalConfig.auth.authority,
            account: client.getActiveAccount() || undefined,
            redirectUri: getRedirectUri()
        })
        .then(r => {
            return r;
        })
        .catch(error => {
            client.logout();
            client.clearCache();
            window.location.href = "/";
            return undefined;
        });
};
