import React, { useState, useEffect, useMemo } from "react";
import { Toggle, TextField, PrimaryButton, DefaultButton, Dropdown, IDropdownOption, Stack, IconButton, Callout, DirectionalHint } from "@fluentui/react";
import styles from "./DocFieldSelection.module.css";
import { DocFieldSelection } from "../../components/DocFieldSelection/DocFieldSelection";
import { libPivotStyles, stackStyles } from "./../../styles_glob";
import { gselectedgroup, gloggedinuser } from "../../interfaces";
import { getHeaderG } from "../../api/qpi";

interface Property {
    name: string;
    description: string;
    type: "string" | "number" | "boolean" | "array" | "object";
    items?: Property[];
}

interface PropertiesEditorProps {
    selectedGroup: gselectedgroup;
    loggedInUser: gloggedinuser;
    onEditDocExtractionDone: () => void;
}

export const DocFieldSettings: React.FC<PropertiesEditorProps> = ({ selectedGroup, loggedInUser, onEditDocExtractionDone }) => {
    const [editedSpace, setEditedSpace] = useState<gselectedgroup>({ ...selectedGroup });
    const [isLoadingAPI, setIsLoadingAPI] = useState(false);

    const docextractionconfig = useMemo(() => {
        try {
            let str = selectedGroup.docextractionconfig.replace(/\\/g, "");
            setEditedSpace(prev => ({ ...prev, docextractionconfig: JSON.stringify(docextractionconfig) }));
            return JSON.parse(str.substring(1, str.length - 1)) || [];
        } catch (e) {
            console.error("Error parsing docextractionconfig", e);
            return [];
        }
    }, [selectedGroup.docextractionconfig]);

    const handleChange = (field: keyof gselectedgroup, value: string | number | boolean) => {
        setEditedSpace(prev => ({ ...prev, [field]: value }));
    };
    const cleanProperties = (properties: Property[]): Property[] => {
        console.log("properties", properties);
        if (!Array.isArray(properties)) return [];

        return properties
            .filter(prop => prop.name !== "")
            .map(prop => {
                const cleaned: Property = {
                    name: prop.name.replace(/\s+/g, "_"), // Replace spaces with underscores
                    description: prop.description,
                    type: prop.type
                };

                // If it's an array type and has items, clean them recursively
                if (prop.type === "array" && prop.items && prop.items.length > 0) {
                    cleaned.items = cleanProperties(prop.items);
                } else if (prop.type === "object" && prop.items && prop.items.length > 0) {
                    cleaned.items = cleanProperties(prop.items);
                }

                return cleaned;
            })
            .filter(prop => {
                // Remove properties that have empty items array when type is 'array'
                if (prop.type === "array" || prop.type === "object") {
                    return prop.items && prop.items.length > 0;
                }
                return true;
            });
    };
    const handleSave = () => {
        setIsLoadingAPI(true);
        let cleanedProps = cleanProperties(JSON.parse(editedSpace.docextractionconfig));
        console.log("cleanedProps", cleanedProps);
        setEditedSpace(prev => ({ ...prev, docextractionconfig: JSON.stringify(cleanedProps) }));
        fetch("/qDocExtrConf", {
            method: "POST",
            headers: getHeaderG(loggedInUser),
            body: JSON.stringify({
                groupid: editedSpace.selectionId,
                docextractionconfig: JSON.stringify(cleanedProps),
                docextractionuseauto: editedSpace.docextractionuseauto
            })
        }).then(() => {
            setIsLoadingAPI(false);
            onEditDocExtractionDone();
        });
    };
    return (
        <div className={styles.editModalWrap}>
            <Stack styles={stackStyles}>
                <div className={styles.editFormSectionTitle}>
                    <span>Document Extraction Settings</span>
                </div>

                <div className={styles.invSetDedcription}>
                    Select the fields that will get extracted from your Invoice. Some essential fields cannot be disabled.
                </div>
                <div className={styles.invSetDedcriptionMid}>
                    Custom instructions can be added for each field with general instructions about what AI should expect and return. These instructions are
                    optional and intended for cases not yet covered out-of-the-box. Keep instructions short and to the point.
                </div>

                <Stack horizontal tokens={{ childrenGap: 10 }} className={styles.docExtrTopsettings}>
                    <Toggle
                        label="Automatic Field Determination"
                        inlineLabel
                        onText="On"
                        offText="Off"
                        checked={editedSpace.docextractionuseauto == 1}
                        onChange={(_event, checked) => {
                            handleChange("docextractionuseauto", checked ? 1 : 0);
                        }}
                    />
                </Stack>

                {editedSpace.docextractionuseauto === 0 && (
                    <DocFieldSelection
                        initialProperties={docextractionconfig}
                        onChange={updated => {
                            console.log("Updated JSON:", updated);
                            handleChange("docextractionconfig", JSON.stringify(updated));
                        }}
                        isRoot={true}
                    />
                )}

                <div className={styles.docExtrSaveButs}>
                    <div />
                    <PrimaryButton
                        text="Save"
                        onClick={() => {
                            handleSave();
                        }}
                        disabled={isLoadingAPI}
                        className={styles.saveSpaceButton}
                    />
                </div>
            </Stack>
        </div>
    );
};
