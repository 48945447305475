// ChartRenderer.tsx
import React from "react";
import {
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    ChartType,
    ArcElement,
    TimeScale,
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale
} from "chart.js";
import "chartjs-adapter-date-fns"; // Import the date adapter
import { Line, Bar, Pie, Doughnut, Radar, PolarArea, Bubble, Scatter } from "react-chartjs-2";
import JSON5 from "json5";

interface ChartConfig {
    type: ChartType;
    data: ChartData;
    options?: ChartOptions;
}

interface ChartRendererProps {
    configJson: string;
}
ChartJS.register(ArcElement, TimeScale, LineController, LineElement, PointElement, LinearScale, CategoryScale);
const ChartRenderer: React.FC<ChartRendererProps> = ({ configJson }) => {
    let config: ChartConfig;

    try {
        let configJ = JSON.parse(configJson);
        if (typeof configJ === "string") {
            config = JSON.parse(configJ) as ChartConfig;
        } else {
            if (configJ.chartjs_json) {
                config = configJ.chartjs_json as ChartConfig;
            } else {
                config = configJ as ChartConfig;
            }
        }
    } catch (error: any) {
        return <div>Error parsing JSON: {error.message}</div>;
    }

    const { type, data, options } = config;
    // Map chart types to components
    const chartComponents: { [key in ChartType]: React.FC<any> } = {
        line: Line,
        bar: Bar,
        pie: Pie,
        doughnut: Doughnut,
        radar: Radar,
        polarArea: PolarArea,
        bubble: Bubble,
        scatter: Scatter
    };

    const ChartComponent = chartComponents[type];

    if (!ChartComponent) {
        return <div>Unsupported Chart Type</div>;
    }

    return <ChartComponent data={data} options={options} />;
};

export default ChartRenderer;
