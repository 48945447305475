import { createTheme, Theme } from "@fluentui/react";

const myCustomTheme: Theme = createTheme({
    palette: {
        themePrimary: "#ff681f",
        themeLighterAlt: "#fff9f6",
        themeLighter: "#ffe4d9",
        themeLight: "#ffccb8",
        themeTertiary: "#ff9d71",
        themeSecondary: "#ff7735",
        themeDarkAlt: "#e65d1c",
        themeDark: "#c24e18",
        themeDarker: "#8f3a12",
        neutralLighterAlt: "#faf9f8",
        neutralLighter: "#f3f2f1",
        neutralLight: "#edebe9",
        neutralQuaternaryAlt: "#e1dfdd",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c6c4",
        neutralTertiary: "#676a8b",
        neutralSecondary: "#7b5597",
        neutralPrimaryAlt: "#405480",
        neutralPrimary: "#2f4858",
        neutralDark: "#151f26",
        black: "#0b0f13",
        white: "#ffffff"
    },
    semanticColors: {
        bodyBackground: "#ffffff",
        bodyText: "#2f4858",
        actionLink: "#333",
        actionLinkHovered: "#499cbf",
        link: "#405480",
        linkHovered: "#7b5597",
        inputBorder: "#7b5597",
        inputBorderHovered: "#405480",
        inputFocusBorderAlt: "#ff681f",
        inputPlaceholderText: "#7b5597",
        menuItemBackgroundHovered: "#fff9f6",
        menuItemBackgroundPressed: "#ffe4d9",
        menuIcon: "#405480",
        menuHeader: "#2f4858"
    },
    defaultFontStyle: {
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif',
        fontWeight: 400
    },
    fonts: {
        small: {
            fontSize: "12px"
        },
        medium: {
            fontSize: "14px"
        },
        large: {
            fontSize: "18px",
            fontWeight: 600
        },
        xLarge: {
            fontSize: "22px",
            fontWeight: 600
        }
    }
});

export default myCustomTheme;
