import { useState, useReducer, useRef } from "react";
import { Stack, TextField, ActionButton, IIconProps, Callout, DirectionalHint, TooltipHost } from "@fluentui/react";

import { Button } from "@fluentui/react-components";
import { Send28Filled, Stop24Filled, List28Regular } from "@fluentui/react-icons";

import styles from "./QuestionInput.module.css";
import { ClearChatButton } from "../../components/ClearChatButton";
import { TogglePanelButton } from "../../components/TogglePanelButton";
import ChatControls from "../../components/ChatControls/ChatControls";
import { AddURLs } from "../../components/AddURLs";

import { gselectedgroup, gtiers, gloggedinuser } from "../../interfaces";
import { actionButtonStyles, clipIcon, questionInputUploadCallout, questionInputTextArea } from "../../styles_glob";

import SpeechRecognizer from "../../components/SpeechRecognizer/SpeechRecognizer";
import { adjustHeight } from "../../util_glob";
interface Props {
    clearOnSend?: boolean;
    isStreaming: boolean;
    disabled: boolean;
    placeholder?: string;
    permissionLevel: number;
    selectedGroup: gselectedgroup;
    lTiers: gtiers[];
    msgLmtHit: string;
    loggedInUser: gloggedinuser;
    onSend: (question: string) => void;
    updateCatsState: (question: string) => void;
    isCancellingMidway: () => void;
    toggleLeftPanel: () => void;
    newConverson: () => void;
    listRefreshRequest: () => void;
    isOnCallback: (isOn: boolean) => void;
}

export const QuestionInput = ({
    clearOnSend,
    isStreaming,
    disabled,
    placeholder,
    permissionLevel,
    selectedGroup,
    lTiers,
    msgLmtHit,
    loggedInUser,
    onSend,
    updateCatsState,
    isCancellingMidway,
    toggleLeftPanel,
    newConverson,
    listRefreshRequest,
    isOnCallback
}: Props) => {
    const [chatControlsOpen, setChatControlsOpen] = useState<boolean>(false);
    const [isFileUploadDiagOpen, setIsFileUploadDiagOpen] = useState<boolean>(false);
    const qRef = useRef<string>("");
    const textAreaRef = useRef(null);
    const uploadButRef = useRef(null);
    const sendButRef = useRef(null);
    const [, forceRender] = useReducer(x => x + 1, 0);

    const onInputChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQ(newValue || "");
    };
    const setQ = (newValue: string) => {
        !newValue ? (qRef.current = "") : (qRef.current = newValue);
        adjustHeight(styles.questionInputContainer);
        forceRender();
    };
    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQ();
        }
    };
    const simulateSendClick = () => {
        const event = new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window
        });
        if (sendButRef.current) (sendButRef.current as any).dispatchEvent(event);
    };
    const sendQ = () => {
        if (disabled || !qRef.current.trim()) return;
        onSend(qRef.current);
        if (clearOnSend) setQ("");
    };
    const handleNewConverson = () => {
        setChatControlsOpen(false);
        newConverson();
    };
    const handleToggleLeftPanel = () => {
        setChatControlsOpen(false);
        toggleLeftPanel();
    };
    const handleButtonClick = () => {
        setIsFileUploadDiagOpen(true);
    };
    const onRecognizing = (text: string, language: string) => {
        setQ(text);
    };
    const onRecognized = (text: string, language: string) => {
        if (text === "" || text.length < 4) return;
        setQ(text);
        simulateSendClick();
    };
    const callOutVisibility = (val: boolean) => {};
    const sendQDisabled = disabled || !qRef.current.trim();

    return (
        <Stack
            horizontal
            className={`${styles.questionInputContainer} ${
                (selectedGroup.premiumby ?? "") == "" && selectedGroup.dailymsgs > (lTiers?.at(0)?.msgsplmt || 0) ? styles.noMsggsConditional : ""
            }`}
        >
            {/* Upload Callout */}
            <Callout
                gapSpace={0}
                styles={questionInputUploadCallout}
                isBeakVisible={false}
                target={uploadButRef.current}
                onDismiss={() => setIsFileUploadDiagOpen(false)}
                setInitialFocus
                directionalHint={DirectionalHint.leftTopEdge}
                className={styles.calloutMain}
                hidden={!isFileUploadDiagOpen}
            >
                <div className={styles.rightButtons}>
                    <div className={styles.libButs} id={`calloutButton_pdf`}>
                        <TooltipHost content="Upload Documents" directionalHint={DirectionalHint.topCenter}>
                            {/* Upload PDFs Button */}
                            <AddURLs
                                className={styles.commandButtonLib}
                                updateCatsState={updateCatsState}
                                butName="Documents"
                                calloutLabelText="Upload PDFs from your Device"
                                cTy="pdf"
                                listRefreshRequest={listRefreshRequest}
                                selectedGroup={selectedGroup}
                                callOutVisibility={callOutVisibility}
                                hideCallout={isFileUploadDiagOpen == false ? true : false}
                            />
                        </TooltipHost>
                    </div>
                    {selectedGroup.allowvideofiles == 1 && (
                        <div className={styles.libButs} id={`calloutButton_vid`}>
                            <TooltipHost content="Add Video Files" directionalHint={DirectionalHint.topCenter}>
                                {/* Upload Video Button */}
                                <AddURLs
                                    className={styles.commandButtonLib}
                                    updateCatsState={(newValue: string) => updateCatsState(newValue)}
                                    butName="Video"
                                    calloutLabelText="Upload Video Files from your Device"
                                    cTy="vid"
                                    listRefreshRequest={listRefreshRequest}
                                    selectedGroup={selectedGroup}
                                    callOutVisibility={callOutVisibility}
                                />
                            </TooltipHost>
                        </div>
                    )}
                    {selectedGroup.allowyoutube == 1 && (
                        <div className={styles.libButs} id={`calloutButton_youtube`}>
                            <TooltipHost content="Add YouTube videos" directionalHint={DirectionalHint.topCenter}>
                                {/* Upload Youtube Button */}
                                <AddURLs
                                    className={styles.commandButtonLib}
                                    updateCatsState={updateCatsState}
                                    butName="YouTube"
                                    calloutLabelText="Enter YouTube URL"
                                    cTy="youtube"
                                    listRefreshRequest={listRefreshRequest}
                                    selectedGroup={selectedGroup}
                                    callOutVisibility={callOutVisibility}
                                    hideCallout={isFileUploadDiagOpen ? false : true}
                                />
                            </TooltipHost>
                        </div>
                    )}
                    {selectedGroup.allowweb == 1 && (
                        <div className={styles.libButs} id={`calloutButton_web`}>
                            <TooltipHost content="Add Web Pages" directionalHint={DirectionalHint.topCenter}>
                                {/* Upload Web Button */}
                                <AddURLs
                                    className={styles.commandButtonLib}
                                    updateCatsState={updateCatsState}
                                    butName="Web"
                                    calloutLabelText="Enter Web URL"
                                    cTy="web"
                                    listRefreshRequest={listRefreshRequest}
                                    selectedGroup={selectedGroup}
                                    callOutVisibility={callOutVisibility}
                                    hideCallout={isFileUploadDiagOpen ? false : true}
                                />
                            </TooltipHost>
                        </div>
                    )}
                    {selectedGroup.allowaudiodocentry == 1 && (
                        <div className={styles.libButs} id={`calloutButton_mic`}>
                            {/* Record Audio Button */}
                            <TooltipHost content="Record Content" directionalHint={DirectionalHint.topCenter}>
                                <AddURLs
                                    className={styles.commandButtonLib}
                                    updateCatsState={updateCatsState}
                                    butName="Mic"
                                    calloutLabelText="Record Audio"
                                    cTy="mic"
                                    listRefreshRequest={listRefreshRequest}
                                    selectedGroup={selectedGroup}
                                    callOutVisibility={callOutVisibility}
                                />
                            </TooltipHost>
                        </div>
                    )}
                </div>
            </Callout>
            {/* NOT USED. Chat Controls Slide Out */}
            {chatControlsOpen && (
                <ChatControls
                    isOpen={chatControlsOpen}
                    onDismiss={() => setChatControlsOpen(false)}
                    ButtonRef={textAreaRef}
                    toggleLeftPanel={handleToggleLeftPanel}
                    newConverson={handleNewConverson}
                />
            )}
            {/* New Convo / Convo History Button Container */}
            <div ref={textAreaRef} className={styles.chatControlsContainer}>
                {/*New Conversation Button */}
                <ClearChatButton className={styles.commandButtonTwo} onClick={newConverson} />
                {/* Show Chat History Button */}
                <TogglePanelButton
                    className={styles.commandButton}
                    onClick={() => handleToggleLeftPanel()}
                    text=""
                    iconType={List28Regular}
                    tooltipText="View your Chat History"
                />
            </div>
            {/* Speech Button */}
            {selectedGroup.convomode == 1 && (
                <SpeechRecognizer onRecognized={onRecognized} onRecognizing={onRecognizing} isOnCallback={isOnCallback} selectedGroup={selectedGroup} />
            )}
            <TextField
                className={styles.questionInputTextArea}
                styles={questionInputTextArea}
                autoAdjustHeight={true}
                placeholder={(selectedGroup.premiumby ?? "") == "" && selectedGroup.dailymsgs > (lTiers?.at(0)?.msgsplmt || 0) ? msgLmtHit : placeholder}
                multiline
                disabled={(selectedGroup.premiumby ?? "") == "" && selectedGroup.dailymsgs > (lTiers?.at(0)?.msgsplmt || 0) ? true : false}
                resizable={false}
                borderless
                value={qRef.current}
                onChange={onInputChange}
                onKeyDown={onEnterPress}
            />
            {/* Show Upload Callout Button */}
            <div ref={uploadButRef} className={styles.questionInputButtonsContainer}>
                {permissionLevel > 1 && (
                    <ActionButton
                        className={styles.btn_action}
                        id="calloutButton"
                        iconProps={clipIcon}
                        styles={actionButtonStyles}
                        allowDisabledFocus
                        onClick={handleButtonClick}
                    ></ActionButton>
                )}
            </div>
            {/* Send Message / Stop Streaming Button */}
            <div className={styles.questionInputButtonsContainer}>
                {isStreaming == true ? (
                    <Button
                        size="large"
                        className={styles.sendButton}
                        icon={<Stop24Filled primaryFill="rgba(255, 255, 255, 0.6)" />}
                        onClick={isCancellingMidway}
                        style={{ backgroundColor: "#F1641E", borderRadius: "5px" }}
                    />
                ) : (
                    <Button
                        size="large"
                        ref={sendButRef}
                        className={styles.sendButton}
                        icon={<Send28Filled primaryFill="rgba(255, 255, 255, 0.6)" />}
                        disabled={sendQDisabled}
                        onClick={sendQ}
                        style={{ backgroundColor: "#F1641E", borderRadius: "5px" }}
                    />
                )}
            </div>
        </Stack>
    );
};
