import { useState, useRef } from "react";
import { Stack, TextField } from "@fluentui/react";
import { Button, Tooltip, Field, Textarea } from "@fluentui/react-components";
import { Send28Filled, List28Regular } from "@fluentui/react-icons";
import { ClearChatButton } from "../../components/ClearChatButton";
import { TogglePanelButton } from "../../components/TogglePanelButton";
import styles from "./QuestionInputXplrr.module.css";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    toggleLeftPanel: () => void;
    newConverson: () => void;
}

export const QuestionInputXplrr = ({ onSend, disabled, placeholder, clearOnSend, toggleLeftPanel, newConverson }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const [chatControlsOpen, setChatControlsOpen] = useState<boolean>(false);

    const textAreaRef = useRef(null);

    const sendQuestion = () => {
        if (disabled || !question.trim()) return;
        onSend(question);
        if (clearOnSend) setQuestion("");
    };
    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };
    const handleToggleLeftPanel = () => {
        setChatControlsOpen(false);
        toggleLeftPanel();
    };
    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };
    const sendQuestionDisabled = disabled || !question.trim();
    return (
        <Stack horizontal className={styles.questionInputContainer}>
            <div ref={textAreaRef} className={styles.chatControlsContainer}>
                <ClearChatButton className={styles.commandButtonTwo} onClick={newConverson} />
                <TogglePanelButton
                    className={styles.commandButton}
                    onClick={() => handleToggleLeftPanel()}
                    text=""
                    iconType={List28Regular}
                    tooltipText="View your Chat History"
                />
            </div>
            <TextField
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                multiline
                autoAdjustHeight
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
            />
            <div className={styles.questionInputButtonsContainer}>
                <Button
                    size="large"
                    className={styles.sendButton}
                    icon={<Send28Filled primaryFill="rgba(255, 255, 255, 0.6)" />}
                    disabled={sendQuestionDisabled}
                    onClick={sendQuestion}
                    style={{ backgroundColor: "#F1641E", borderRadius: "5px" }}
                />
            </div>
        </Stack>
    );
};
