export const prepRegex = (filterString: string) => {
    // Split the string in half
    const halfLength = Math.floor(filterString.length / 2);
    let startPart = filterString.slice(0, halfLength);
    let endPart = filterString.slice(halfLength);

    // Get the first and last 10 characters (or less if the string is shorter)
    startPart = startPart.slice(0, 15);
    endPart = endPart.slice(-15);

    // Escape special regex characters
    const escapeRegex = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    startPart = escapeRegex(startPart);
    endPart = escapeRegex(endPart);

    // Create the regex pattern
    const regexPattern = `${startPart}[\\s\\S]*?${endPart}`;
    return new RegExp(regexPattern, "g");
};
export const filterString = (str: string | undefined): string => {
    return (str || "")
        .toLowerCase()
        .replace(/[^а-яa-z0-9]/gi, "")
        .trim();
};
